import { RANGE_OF_GAME_REQUEST } from 'shared/constants/constants';
import {
  useReadRspContractActiveRoomCounter,
  useReadRspContractGetRoomsInfo,
  useReadRspContractRoomIdCounter,
} from 'shared/features/contract.abi';
import { useCurrenciesStore } from 'shared/store/currenciesStore';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import ClockIcon from 'assets/clock.svg?react';
import PersonIcon from 'assets/person.svg?react';
import CurrencyIcon from 'assets/currency.svg?react';
import FilterIcon from 'assets/filter.svg?react';
import ClockSmallIcon from 'assets/clock-small.svg?react';
import CurrencySmallIcon from 'assets/currency-small.svg?react';
import FilterSmallIcon from 'assets/filter-small.svg?react';
import Dropdown from 'shared/components/DropDown/DropDown';
import ArrowIcon from 'assets/arrow.svg?react';
import { ActiveRoom, RoomInfo } from 'shared/types/app-types';
import {
  RoomStatusEnum,
  WinnerStatusEnum,
} from 'shared/constants/shared-enums';
import { useAccount } from 'wagmi';
import { useBlockToFindCreateAndJoin } from 'modules/Room/Hooks/useBlockToFindCreateAndJoin';
import { LiveGameItemMobile } from './live-game-item-mobile';
import useMediaQuery from 'shared/hooks/useMediaQuery';
import { useTranslation } from 'react-i18next';
import { NoTableImage } from 'modules/lobby-page/components/live-games-section/no-table-image';
import ControllerIcon from 'assets/controller.svg?react';
import { TimeAgo } from 'shared/components/time-ago/time-ago';
import { SearchBarMobile } from 'shared/components/SearchBar/SearchBarMobile';
import { useGameStatusStore } from '@/modules/Room/game-status/store/game-status-store';
import { GameStatusEnum } from '@/modules/Room/game-status/constants/game-status-enum';

interface SortingConfig {
  key: 'roomId' | 'amount';
  order: 'asc' | 'desc';
}

const chains = {
  1: 'ETH',
  137: 'Polygon',
  42161: 'Arbitrum',
  8453: 'Base',
};

const tableTitleStyles =
  'text-[#F7F7F780] text-[10px] sm:text-[20px] font-medium flex gap-2 items-center';
const tableSubTitleStyles =
  'text-[#F7F7F7BF] text-[12px] sm:text-[20px] font-semibold';
const tableColumnStyles = 'flex flex-col';
const sectionStyles =
  'relative overflow-hidden z-10 w-full bg-[linear-gradient(0deg,#080540,#080540),radial-gradient(50%_50%_at_50%_50%,rgba(0,11,255,0.3)_0%,rgba(1,0,29,0.3)_100%)] border-[1px] lg:border-[3px] border-[#202066] rounded-[8px] lg:rounded-[16px] p-[10px] lg:px-[24px] lg:py-[20px] mt-[12px]';
const sectionContainerStyles =
  'grid grid-cols-[1.2fr_0.8fr_0.8fr_1fr] lg:gap-8 relative';
const sectionCurrencyFilterStyles =
  'text-white/75 flex gap-2 items-center text-[10px] sm:text-[20px] relative';
const sectionRoomsListStyles =
  'flex flex-col gap-2 sm:gap-4 overflow-scroll mt-[8px] lg:mt-[20px] no-scrollbar overflow-x-hidden  md:pr-[20px] relative';
const sectionTitleStyles =
  'font-bold text-[20px] lg:text-[52px] gradient-title';

interface Props {
  openCreateGame: () => void;
}

export const LiveGamesSectionMobile = ({ openCreateGame }: Props) => {
  const [currencyFilter, setCurrencyFilter] = useState<string>('All');
  const [isCurrencyFilterOpen, setIsCurrencyFilterOpen] = useState(false);
  const { address } = useAccount();
  const [activeRooms, setActiveRooms] = useState<RoomInfo[]>([]);
  const [parsedActiveRooms, setParsedActiveRooms] = useState<ActiveRoom[]>([]);
  const [sortingConfig, setSortingConfig] = useState<SortingConfig>({
    key: 'roomId',
    order: 'desc',
  });
  const hasNavigatedRef = useRef(false);
  const [isJoinRoomGlobalLoading, setIsJoinRoomGlobalLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [noSearchResults, setNoSearchResults] = useState(false);
  const { setGameStatus } = useGameStatusStore();
  const { t } = useTranslation();
  const { availableCurrencies, currencyForSelect } = useCurrenciesStore();
  const isTablet = useMediaQuery('(min-width: 768px)');
  const { chainId } = useAccount();
  // Set active rooms logic
  const [roomIds, setRoomIds] = useState({
    fromRoomId: 0n,
    toRoomId: 0n,
  });
  const [searchOpen, setSearchOpen] = useState(false);
  const { data: lastRoomId, isSuccess: lastRoomIdSuccess } =
    useReadRspContractRoomIdCounter({
      query: {
        staleTime: 0,
      },
    });
  const { data: roomsInfo, isSuccess: roomsInfoSuccess } =
    useReadRspContractGetRoomsInfo({
      args: [roomIds.fromRoomId, roomIds.toRoomId],
      query: {
        enabled: roomIds.toRoomId !== 0n,
        staleTime: 0,
        refetchOnMount: true,
      },
    });
  const { data: activeRoomCounter, isSuccess: activeRoomCounterSuccess } =
    useReadRspContractActiveRoomCounter({
      query: {
        staleTime: 0,
        refetchOnMount: true,
        refetchOnWindowFocus: true,
      },
    });

  useEffect(() => {
    if (
      activeRoomCounterSuccess &&
      activeRoomCounter > 0n &&
      roomsInfoSuccess &&
      !hasNavigatedRef.current &&
      address
    ) {
      const activeRoom = activeRooms.findLast((item) => {
        return (
          [
            item.data.playerA.toLowerCase(),
            item.data.playerB.toLowerCase(),
          ].includes(address.toLowerCase()) &&
          item.status !== RoomStatusEnum.Closed &&
          item.winnerStatus === WinnerStatusEnum.None
        );
      });
      if (activeRooms.length < Number(activeRoomCounter) && !activeRoom) {
        setRoomIds({
          fromRoomId:
            roomIds.fromRoomId - BigInt(RANGE_OF_GAME_REQUEST) < 0n
              ? 0n
              : roomIds.fromRoomId - BigInt(RANGE_OF_GAME_REQUEST),
          toRoomId: roomIds.fromRoomId,
        });
      }
    }
  }, [
    activeRooms,
    activeRoomCounter,
    roomsInfoSuccess,
    activeRoomCounterSuccess,
  ]);
  useEffect(() => {
    if (lastRoomId && lastRoomIdSuccess && !hasNavigatedRef.current) {
      setRoomIds({
        fromRoomId:
          lastRoomId - BigInt(RANGE_OF_GAME_REQUEST) < 0n
            ? 0n
            : lastRoomId - BigInt(RANGE_OF_GAME_REQUEST),
        toRoomId: lastRoomId ?? 0n,
      });
    }
  }, [lastRoomId, lastRoomIdSuccess]);
  useEffect(() => {
    if (roomsInfo && roomsInfoSuccess && !hasNavigatedRef.current) {
      const uniqueRooms = Array.from(
        new Map(
          [
            ...activeRooms,
            ...roomsInfo.filter(
              (room: any) =>
                room.status === RoomStatusEnum.Open && room.data.roomId !== 0n
            ),
          ].map((item) => [item.data.roomId, item])
        ).values()
      );

      setActiveRooms(uniqueRooms);
    }
  }, [roomsInfo, roomsInfoSuccess]);
  useEffect(() => {
    const newParsedActiveRooms = activeRooms
      ?.map((item) => {
        const roomCurrency = availableCurrencies.find(
          (currency) =>
            currency.address.toLowerCase() === item.data.token.toLowerCase()
        );

        if (!roomCurrency) {
          return null;
        }

        const roomBet = Number(item.data.bet) / 10 ** roomCurrency.decimals;

        return {
          playerAddress: item.data.playerA,
          currency: roomCurrency,
          amount: roomBet,
          roomId: item.data.roomId,
        };
      })
      .filter((item) => !!item);

    setParsedActiveRooms(newParsedActiveRooms as ActiveRoom[]);
  }, [activeRooms]);

  // Update active rooms logic
  const { roomsToRemove, roomsToAdd, blockNumber, time } =
    useBlockToFindCreateAndJoin();

  useEffect(() => {
    if (roomsToRemove.length) {
      const deleteIds = new Set(roomsToRemove.map((item) => item.roomId));

      if (!deleteIds.size) {
        return;
      }

      const updatedRooms = parsedActiveRooms.filter(
        (activeRoom) => !deleteIds.has(activeRoom.roomId)
      );

      setParsedActiveRooms(updatedRooms);
    }
  }, [roomsToRemove]);

  useEffect(() => {
    if (roomsToAdd.length) {
      const existingRoomIds = new Set(
        parsedActiveRooms.map((room) => room.roomId)
      );

      const newRooms = roomsToAdd.filter(
        (room) => !existingRoomIds.has(room.roomId)
      );
      setParsedActiveRooms([...newRooms, ...parsedActiveRooms]);
    }
  }, [roomsToAdd]);

  const handleToggleCurrencyFilter = () => {
    setIsCurrencyFilterOpen(!isCurrencyFilterOpen);
  };

  const handleChangeCurrencyFilter = (value: string) => {
    setCurrencyFilter(value);
  };

  const handleResetRooms = () => {
    setGameStatus(GameStatusEnum.SET_BET, false);
  };

  const handleSetIsJoinRoomGlobalLoading = (value: boolean) => {
    setIsJoinRoomGlobalLoading(value);
  };

  const handleSort = (key: 'roomId' | 'amount') => {
    setSortingConfig((prevConfig) => ({
      key,
      order:
        prevConfig.key === key && prevConfig.order === 'asc' ? 'desc' : 'asc',
    }));
  };

  const sortedRooms = useMemo(() => {
    let sortableRooms = [...parsedActiveRooms];

    sortableRooms.sort((a, b) => {
      if (a[sortingConfig.key] < b[sortingConfig.key]) {
        return sortingConfig.order === 'asc' ? -1 : 1;
      }
      if (a[sortingConfig.key] > b[sortingConfig.key]) {
        return sortingConfig.order === 'asc' ? 1 : -1;
      }
      return 0;
    });

    return sortableRooms;
  }, [parsedActiveRooms, sortingConfig]);

  const renderGamesList = () => {
    const filteredRooms = searchValue
      ? sortedRooms?.filter(
          (item) =>
            item.playerAddress
              .toLowerCase()
              .includes(searchValue.toLowerCase()) ||
            item.roomId.toString().includes(searchValue)
        )
      : sortedRooms?.filter(
          (item) =>
            currencyFilter === 'All' || item.currency?.symbol === currencyFilter
        );

    return filteredRooms.map((item) => (
      <LiveGameItemMobile
        key={`${item.roomId.toString()}_${item.playerAddress}`}
        roomId={item.roomId}
        address={item.playerAddress}
        amount={item.amount}
        currency={item.currency}
        onJoinRoomCallback={handleResetRooms}
        isJoinRoomGlobalLoading={isJoinRoomGlobalLoading}
        onSetIsJoinRoomGlobalLoading={handleSetIsJoinRoomGlobalLoading}
      />
    ));
  };

  useEffect(() => {
    const filteredRooms = searchValue
      ? sortedRooms?.filter(
          (item) =>
            item.playerAddress
              .toLowerCase()
              .includes(searchValue.toLowerCase()) ||
            item.roomId.toString().includes(searchValue)
        )
      : sortedRooms;

    setNoSearchResults(searchValue ? filteredRooms?.length === 0 : false);
  }, [searchValue, sortedRooms]);
  const getTimeAgo = (time: number) => {
    const now = Date.now();
    const diffInSeconds = Math.floor((now - time) / 1000);

    if (diffInSeconds < 60) {
      return `${diffInSeconds} seconds ago`;
    } else {
      const diffInMinutes = Math.floor(diffInSeconds / 60);
      return `${diffInMinutes} minutes ago`;
    }
  };

  return (
    <div className="w-full max-w-[1184px]  relative z-10 mt-6">
      <div className="px-4">
        <div className={sectionContainerStyles}>
          <div className={`${tableColumnStyles} ${searchOpen ? 'hidden' : ''}`}>
            <span
              className={`${tableTitleStyles} cursor-pointer`}
              onClick={() => handleSort('roomId')}
            >
              {t('pages.liveGamesSection.createdBy')}
              <PersonIcon className='w-4 h-4' />
            </span>
            <span className={tableSubTitleStyles}>
              {t('pages.liveGamesSection.roomId')}
            </span>
          </div>
          {/* <div className={`${tableColumnStyles} col-span-2`}>
              <span className={`${tableTitleStyles} cursor-pointer`} onClick={() => handleSort('roomId')}>Date created <ClockIcon /></span>
              <span className={tableSubTitleStyles}>
                {sortingConfig.key === 'roomId' ?
                (sortingConfig.order === 'asc' ? 'Oldest Games' : 'Newest Games')
                : 'Newest Games'}
              </span>
            </div> */}
          <div
            className={`${tableColumnStyles}  ${searchOpen ? 'hidden' : ''}`}
          >
            <span
              className={`${tableTitleStyles} cursor-pointer`}
              onClick={() => handleSort('amount')}
            >
              {t('pages.liveGamesSection.bet')}
              {isTablet ? <FilterIcon /> : <FilterSmallIcon />}
            </span>
            <span className={tableSubTitleStyles}>
              {sortingConfig.key === 'amount'
                ? sortingConfig.order === 'asc'
                  ? t('pages.liveGamesSection.lowToHighMob')
                  : t('pages.liveGamesSection.lowToHighMob')
                : t('pages.liveGamesSection.lowToHighMob')}
            </span>
          </div>
          <div className={`${tableColumnStyles} ${searchOpen ? 'hidden' : ''}`}>
            <span className={tableTitleStyles}>
              {t('pages.liveGamesSection.currency')}
              {isTablet ? <CurrencyIcon /> : <CurrencySmallIcon />}
            </span>
            <div
              onClick={handleToggleCurrencyFilter}
              className={sectionCurrencyFilterStyles}
            >
              {currencyFilter}
              <ArrowIcon
                className={`${
                  isCurrencyFilterOpen ? 'transform rotate-180' : ''
                } transition ease-in-out duration-500`}
              />

              <Dropdown
                open={isCurrencyFilterOpen}
                list={[{ value: 'All', label: 'All' }, ...currencyForSelect]}
                onClick={(option) => handleChangeCurrencyFilter(option.label)}
                active={currencyFilter}
                position={{ top: 30, left: 0 }}
              />
            </div>
          </div>
          <div className={searchOpen ? 'col-span-4' : ''}>
            <SearchBarMobile
              setSearch={setSearchValue}
              searchOpen={searchOpen}
              setSearchOpen={setSearchOpen}
            />
          </div>
        </div>

        <div className={sectionRoomsListStyles}>
          {sortedRooms.length === 0 || !sortedRooms || noSearchResults ? (
            <div className='w-full h-[80%] relative overflow-hidden min-h-[400px] border border-border rounded-[8px]'>
              <NoTableImage noSearchResults={noSearchResults} />
            </div>
          ) : (
            renderGamesList()
          )}
        </div>
      </div>
      <div className="bg-grape flex justify-center items-center flex-col p-4 fixed bottom-0 w-full">
        <button
          className={`rounded-[12px] w-full mb-3`}
          onClick={openCreateGame}
        >
          <div
            className={`justify-center gap-2 rounded-[8px] border border-[#FFE081] flex m-[1px] p-4 sm:px-[35px] bg-create-btn text-white font-semibold text-[16px] sm:text-[22px]`}
          >
            <ControllerIcon className="w-5 h-5" />
            {t('pages.liveGamesSection.create')}
          </div>
        </button>
        <div className="text-[8px] text-block-text flex gap-2">
          {!!blockNumber && (
            <span>
              {chainId && chains[chainId as keyof typeof chains]} block: {+blockNumber.toString()}
            </span>
          )}
          <span>|</span>
          <TimeAgo time={time} />
        </div>
      </div>
    </div>
  );
};
