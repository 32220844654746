import React, { useEffect, useState } from 'react';
import Allowance from 'modules/Room/features/Allowance/Allowance';
import { useTranslation } from 'react-i18next';
import { useCurrenciesStore } from 'shared/store/currenciesStore';
import Select, { selectOption } from 'shared/components/Select/Select';
import TextWithLeftColorLine from 'shared/components/TextWithLeftColorLine/TextWithLeftColorLine';
import BetInput from 'shared/components/BetInput/BetInput';
import SecondaryBtn from 'shared/components/SecondaryBtn/SecondaryBtn';
import { formatNumber } from 'shared/web3/utils';
import { useBalance } from 'shared/hooks/useBalance';
import { useAccount } from 'wagmi';
import GuysLoader from 'shared/components/Loaders/GuysLoader/GuysLoader';
import { usePlayerGamesStore } from 'shared/store/playerGamesStore';
import { allowanceMessages } from 'shared/constants/text-loaders';
import AnimatedTransition from 'shared/components/AnimatedTransition/AnimatedTransition';

const regex = /^-?\d*\.?\d+$/;

export const CreateGameSection = () => {
  const { t } = useTranslation();
  const [bet, setBet] = useState('5');
  const [betIsValid, setBetIsValid] = useState(true);
  const [allowancePending, setAllowancePending] = useState(false);
  const [allowanceFetching, setAllowanceFetching] = useState(false);
  const { address, chainId } = useAccount();

  const {
    setUserBet,
    currencyForSelect,
    selectedCurrency,
    setSelectedCurrency,
    userBets,
  } = useCurrenciesStore();

  const { allowanceTransactions } = usePlayerGamesStore();

  useEffect(() => {
    if (selectedCurrency && chainId) {
      setBet(
        String(
          userBets?.[chainId]?.[selectedCurrency?.symbol] ??
          selectedCurrency.bet[1]
        )
      );
    }
  }, [selectedCurrency, chainId]);

  const handleSelectCurrency = (currency: selectOption) => {
    setSelectedCurrency(currency.value as string);
  };
  const handleSetBetInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    value = value.replace(/[^0-9.,]/g, '');
    value = value.replace(/,/g, '.');
    value = value.replace(/(\..*)\./g, '$1');
    value = value.replace(/^\./, '');
    if (value.startsWith('0') && value[1] && !['.', ','].includes(value[1])) {
      value = `0.${value.slice(1)}`;
    }
    let moreThanMin = +value >= selectedCurrency!.minBet;
    let valid = regex.test(value) && moreThanMin;
    setBetIsValid(valid);
    if (valid && chainId) {
      setUserBet(chainId, +value, selectedCurrency!.symbol);
    }
    setBet(value);
  };
  const handleSetBet = (value: string) => {
    const regex = /^-?\d*\.?\d+$/;
    setBetIsValid(regex.test(value));
    setBet(value);
    if (chainId) setUserBet(chainId, +value, selectedCurrency!.symbol);
  };
  const { balance } = useBalance({
    currencyAddress: selectedCurrency?.address,
    walletAddress: address as `0x${string}`,
  });
  
  if (!selectedCurrency) return null;

  return (
    <div className='relative z-10'>
      <div className="font-bold text-[20px] lg:text-[52px] gradient-title">
        {t('modals.setBet.title')}
      </div>
      <div className="p-[20px] sm:p-[30px] relative w-full lg:max-w-[578px] lg:min-w-[578px] bg-[linear-gradient(0deg,#080540,#080540),radial-gradient(50%_50%_at_50%_50%,rgba(0,11,255,0.3)_0%,rgba(1,0,29,0.3)_100%)] border-[1px] border-[#202066] rounded-[8px] lg:rounded-[16px] lg:border-[3px] mt-[12px]">
        <AnimatedTransition show={allowancePending}>
          <GuysLoader
            hash={allowanceTransactions.hash}
            messages={allowanceMessages}
            className="absolute top-0 left-0 rounded-[4px] sm:rounded-[14px] w-full h-full bg-[#000014D9] bg-opacity-85 z-50"
          />
        </AnimatedTransition>
        <TextWithLeftColorLine colorClass="bg-green" className="mb-[25px]">
          <p className="text-white text-[12px] sm:text-[16px]">
            {t('modals.setBet.select')}
          </p>
        </TextWithLeftColorLine>
        <div className="flex w-full mb-[25px] items-start justify-between">
          <div className="w-[70%] sm:flex-grow flex flex-col  mr-[10px] sm:mr-[22px]">
            <BetInput
              value={bet.toString()}
              valueHandler={handleSetBetInput}
              type="number"
              className="sm:flex-grow"
              balance={balance ?? 0}
              disabled={allowancePending}
            />
          </div>
          <Select
            className="flex-grow"
            options={currencyForSelect}
            selectedOption={{
              value: selectedCurrency.symbol,
              label: selectedCurrency.symbol,
            }}
            onSelect={handleSelectCurrency}
            disabled={allowancePending}
          />
        </div>
        <div className="flex flex-wrap gap-[14px] pb-[20px] border-border border-b-2 mb-[20px]">
          {selectedCurrency?.bet &&
            selectedCurrency.bet.map((betValue, index) => (
              <SecondaryBtn
                key={index}
                className={`flex-grow`}
                text={`${formatNumber(+betValue)} ${selectedCurrency.symbol}`}
                value={betValue}
                onClick={() => handleSetBet(betValue)}
                disabled={allowancePending}
              />
            ))}
        </div>
        <Allowance
          preferredBet={+bet}
          betIsValid={betIsValid}
          setAllowancePending={setAllowancePending}
          setAllowanceFetching={setAllowanceFetching}
          balance={balance}
        />
      </div>
    </div>
  );
};
