import { useEffect, useRef, useState } from 'react';
import DigitalClock from 'shared/components/DigitalClock/DigitalClock';
import { useTimersStore } from 'shared/store/timersStore';
import { useWaitYourMoveGameStatusText } from './use-wait-your-move-game-status-text';
import { TIME_BEFORE_SHOW_TIMER_IN_MODALS } from 'shared/constants/timers';
import { Timer } from 'shared/constants/types';
import PrimaryButton from 'shared/components/PrimaryButton/PrimaryButton';
import { epochToLocalUTC } from 'shared/utils/time';
import { useRoomStore } from 'shared/store/roomStore';
import AnimatedTransition from 'shared/components/AnimatedTransition/AnimatedTransition';
import { useCloseUnusedRoom } from '../../../../hooks/use-close-unused-room';
import { useParams } from 'react-router-dom';
import { useAccount } from 'wagmi';
import { usePlayerGamesStore } from 'shared/store/playerGamesStore';
import MintingLoader from 'shared/components/Loaders/MintingLoader/MintingLoader';
import { technicalDefeatMessages } from 'shared/constants/text-loaders';

export const WaitYourMoveStatus = () => {
  const { waitYourMoveShowTimer, setWaitYourMoveShowTimer } = useTimersStore();
  const { activeRoomId } = useParams();
  const { address, chainId } = useAccount();
  const [showTimer, setShowTimer] = useState(false);
  const [showCloseButton, setShowCloseButton] = useState(false);
  const timeOutRef = useRef<null | Timer>(null);
  const { closerRoom, roundCounter, lastRoundOutcome, setRoomExpire } =
    useRoomStore();
  const { closeHash } = usePlayerGamesStore();
  const onExpire = () => {
    setShowCloseButton(true);
    setRoomExpire(true);
  };
  useEffect(() => {
    if (
      !waitYourMoveShowTimer?.[activeRoomId! + address + chainId + roundCounter]
    ) {
      if (new Date() >= epochToLocalUTC(closerRoom?.deadline)) {
        setShowTimer(true);
      } else {
        setWaitYourMoveShowTimer(
          activeRoomId! + address + chainId + roundCounter,
          new Date(new Date().getTime() + TIME_BEFORE_SHOW_TIMER_IN_MODALS)
        );
        timeOutRef.current = setTimeout(() => {
          setShowTimer(true);
        }, TIME_BEFORE_SHOW_TIMER_IN_MODALS);
      }
    } else {
      const currentTime = new Date();
      const targetTime = new Date(
        waitYourMoveShowTimer?.[
        activeRoomId! + address + chainId + roundCounter
          ] as string
      );
      const diffInMilliseconds = targetTime.getTime() - currentTime.getTime();
      if (diffInMilliseconds >= TIME_BEFORE_SHOW_TIMER_IN_MODALS) {
        setShowTimer(true);
      } else {
        const timeRemaining = targetTime.getTime() - currentTime.getTime();
        timeOutRef.current = setTimeout(() => {
          setShowTimer(true);
        }, timeRemaining);
      }
    }
    return () => clearTimeout(timeOutRef.current as Timer);
  }, []);

  const { closeUnusedRoomHandler, isSuccess, technicalDefeatError } =
    useCloseUnusedRoom();
  const { getTextComponent } = useWaitYourMoveGameStatusText(
    isSuccess,
    showTimer,
    showCloseButton,
    technicalDefeatError,
    lastRoundOutcome
  );

  return (
    <div className="sm:w-full p-[20px] sm:p-[30px] border border-border rounded-[12px] sm:rounded-[0] sm:border-0 bg-grape sm:bg-[transparent]">
      <AnimatedTransition show={!!closeHash[chainId!]?.hash}>
        <MintingLoader
          className="absolute top-2/3 sm:top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
          hash={closeHash[chainId!]?.hash}
          messages={technicalDefeatMessages}
        />
      </AnimatedTransition>
      <AnimatedTransition show={!closeHash[chainId!]?.hash}>
        {getTextComponent()}
        <AnimatedTransition show={showCloseButton && !isSuccess}>
          <div className="flex justify-center mt-[20px]">
            <PrimaryButton
              text={'common.technicalDefeat'}
              onClick={closeUnusedRoomHandler}
            />
          </div>
        </AnimatedTransition>
        <AnimatedTransition show={showTimer && !showCloseButton}>
          <div className='w-full flex justify-center mt-[24px]'>
            <DigitalClock
              key={(epochToLocalUTC(closerRoom?.deadline) as Date).toString()}
              expiryTimestamp={epochToLocalUTC(closerRoom?.deadline)}
              className='w-fit'
              onExpire={onExpire}
            />
          </div>
        </AnimatedTransition>
      </AnimatedTransition>
    </div>
  );
};
