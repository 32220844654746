import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import AnimatedTransition from 'shared/components/AnimatedTransition/AnimatedTransition';
import MintingLoader from 'shared/components/Loaders/MintingLoader/MintingLoader';
import {
  technicalDefeatMessages,
  waitYourMoveMessages,
} from 'shared/constants/text-loaders';
import { useAccount } from 'wagmi';
import { usePlayerGamesStore } from 'shared/store/playerGamesStore';
import { useRoomStore } from 'shared/store/roomStore';
import { useParams } from 'react-router-dom';
import { useTimerLogic } from '../../hooks/use-timer-logic';
import PrimaryButton from '@/shared/components/PrimaryButton/PrimaryButton';
import DigitalClock from '@/shared/components/DigitalClock/DigitalClock';
import { epochToLocalUTC } from '@/shared/utils/time';

export const WaitYourApproveMoveStatus = () => {
  const [showTimer, setShowTimer] = useState(false);
  const [showCloseButton, setShowCloseButton] = useState(false);
  const [technicalDefeatError, setTechnicalDefeatError] = useState(false);
  const { t } = useTranslation();
  const { chainId } = useAccount();
  const { closeHash, playMoves } = usePlayerGamesStore();
  const { roundCounter, closerRoom } = useRoomStore();
  const { address } = useAccount();
  const { activeRoomId } = useParams();

  const { 
    isSuccess,
    closeUnusedRoomHandler,
    onExpire
  } = useTimerLogic({
    selector: '.player-card-host',
    gameStatusName: "waitYourMove",
    setShowTimer,
    setShowCloseButton,
    setTechnicalDefeatError
  });

  return (
    <div className="sm:w-full p-[20px] sm:p-[30px] border border-border rounded-[12px] sm:rounded-[0] sm:border-0 bg-grape sm:bg-[transparent]">
      <AnimatedTransition
        show={
          !!(
            closeHash[chainId!]?.hash ||
            playMoves[activeRoomId! + address! + chainId + roundCounter]
              ?.moveHash?.hash
          )
        }
      >
        <MintingLoader
          className="absolute top-2/3 sm:top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
          hash={
            closeHash[chainId!]?.hash ??
            playMoves[activeRoomId! + address! + chainId + roundCounter]
              ?.moveHash?.hash
          }
          selector=".player-card-host"
          messages={
            !!closeHash[chainId!]?.hash
              ? technicalDefeatMessages
              : waitYourMoveMessages
          }
        />
      </AnimatedTransition>
      <AnimatedTransition
        show={
          !closeHash[chainId!]?.hash &&
          !playMoves[activeRoomId! + address! + chainId + roundCounter]
            ?.moveHash?.hash
        }
      >
        <AnimatedTransition
          show={!showTimer && !showCloseButton && !technicalDefeatError}
        >
          <p className="text-[14px] sm:text-[24px] mb-[10px] text-green font-bold">
            {t('modals.approveYourMove.approve')}
          </p>
          <p className="text-white text-[10px] sm:text-[16px]">
            {t('modals.approveYourMove.confirm')}
          </p>
        </AnimatedTransition>
        <AnimatedTransition show={showTimer}>
          <p className="text-[14px] sm:text-[24px] mb-[10px] text-green font-bold">
            {t('modals.approveYourMove.approve')}
          </p>
          <p className="text-white text-[10px] mb-[10px] sm:text-[16px]">
            {t('modals.approveYourMove.confirm')}
          </p>
          <p className="text-white text-[10px] sm:text-[16px]">
            {t('modals.approveYourMove.remember')}
          </p>
        </AnimatedTransition>
        <AnimatedTransition show={showCloseButton}>
          <p className="text-[14px] sm:text-[24px] mb-[10px] text-red font-bold">
            {t('modals.approveYourMove.gameOver')}
          </p>
          <p className="text-white text-[10px] sm:text-[16px]">
            {t('modals.approveYourMove.technicalDefeatExplanation')}
          </p>
        </AnimatedTransition>
        <AnimatedTransition show={technicalDefeatError}>
          {technicalDefeatError.toString()}
          <p className="text-[14px] sm:text-[24px] mb-[10px] text-red font-bold">
            {t('modals.approveYourMove.technicalDefeatError')}
          </p>
          <p className="text-white text-[10px] sm:text-[16px]">
            {t('modals.approveYourMove.technicalDefeatErrorExplanation')}
          </p>
        </AnimatedTransition>

        <AnimatedTransition show={showCloseButton && !isSuccess}>
          <div className="flex justify-center mt-[20px]">
            <PrimaryButton
              text={'common.technicalDefeat'}
              onClick={closeUnusedRoomHandler}
            />
          </div>
        </AnimatedTransition>
        <AnimatedTransition show={showTimer && !showCloseButton}>
          <div className='w-full flex justify-center mt-[24px]'>
            <DigitalClock
              key={(epochToLocalUTC(closerRoom?.deadline) as Date).toString()}
              expiryTimestamp={epochToLocalUTC(closerRoom?.deadline)}
              className='w-fit'
              onExpire={onExpire}
            />
          </div>
        </AnimatedTransition>
      </AnimatedTransition>
    </div>
  );
};
