import React from 'react';
import ModalWithFullScreenBg from 'shared/components/Modal/ModalWithFullScreenBg';
import TextWithLeftColorLine from 'shared/components/TextWithLeftColorLine/TextWithLeftColorLine';
import { useTranslation } from 'react-i18next';
import PrimaryButton from 'shared/components/PrimaryButton/PrimaryButton';
import { usePlayerGamesStore } from 'shared/store/playerGamesStore';
import { useGameStatusStore } from '../../../store/game-status-store';
import { GameStatusEnum } from '../../../constants/game-status-enum';
import { ModalPositionEnum } from '@/shared/components/Modal/constants';

interface Props {
  open: boolean;
}

export const TransactionTakeToLongModal = ({ open }: Props) => {
  const { t } = useTranslation();
  const { transactionCallBack } = usePlayerGamesStore();
  const { setGameStatus } = useGameStatusStore();
  const repeatTransactionHandler = () => {
    setGameStatus(GameStatusEnum.TRANSACTION_TAKE_TO_LONG, false);
    transactionCallBack && transactionCallBack(600000n);
  };
  return (
    <ModalWithFullScreenBg
      className="bg-black bg-opacity-30 z-40"
      open={open}
      position={ModalPositionEnum.CENTER}
    >
      <div className="sm:w-full sm:min-w-[592px] p-[20px] sm:p-[30px] relative">
        <TextWithLeftColorLine colorClass="bg-red" className="mb-[25px]">
          <p className="text-white text-[12px] sm:text-[17px]">
            {t('modals.transactionTakeToLong.transaction')}
          </p>
        </TextWithLeftColorLine>
        <div className="w-full flex justify-around">
          <PrimaryButton
            text={'modals.transactionTakeToLong.repeatTransaction'}
            onClick={repeatTransactionHandler}
          />
        </div>
      </div>
    </ModalWithFullScreenBg>
  );
};
