import './base.css';
import { useAccountEffect, useAccount } from 'wagmi';
import { useRoomStore } from 'shared/store/roomStore';
import GridBackground from 'shared/components/GridBackground/GridBackground';
import { useGetURLRef } from 'shared/hooks/useGetURLRef';
// import ReactGA from 'react-ga4';
import { Suspense, useEffect } from 'react';
import * as Sentry from '@sentry/react';
import ErrorBoundary from 'shared/components/ErrorBoundary/ErrorBoundary';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { Room } from 'modules/Room/pages/Room';
import { ROUTES } from 'shared/constants';
import ConnectPage from 'modules/ConnectPage/page/ConnectPage';
import { useCurrenciesStore } from 'shared/store/currenciesStore';
import { getAvailableCurrencies } from 'shared/web3';
import { Chain } from 'viem';
import { arbitrum, base, polygon } from '@wagmi/core/chains';
// import ReactPixel from 'react-facebook-pixel';
import { LobbyPage } from 'modules/lobby-page/pages';
import { useFindGames } from 'modules/Room/Hooks/useFindGames';
import { StreamRoom } from '@/modules/stream-room-page';
import { ConnectWallet } from '@/shared/testing-add-only-wagmi-for-wallet-connection/ConnectWallet';

// let googleAnalytics = import.meta.env.VITE_GOOGLE_ANALYTICS;

// const loadTwitterTracking = () => {
//   !(function (e, t, n, s, u, a) {
//     e.twq ||
//       ((s = e.twq =
//         function () {
//           s.exe ? s.exe.apply(s, arguments) : s.queue.push(arguments);
//         }),
//       (s.version = '1.1'),
//       (s.queue = []),
//       (u = t.createElement(n)),
//       (u.async = !0),
//       (u.src = 'https://static.ads-twitter.com/uwt.js'),
//       (a = t.getElementsByTagName(n)[0]),
//       a.parentNode.insertBefore(u, a));
//   })(window, document, 'script');
//
//   // Twitter tracking configuration
//   window.twq('config', 'op1jf');
// };


const App = () => {
  // useEffect(() => {
  //   if (googleAnalytics) ReactGA.initialize(googleAnalytics);
  //   loadTwitterTracking();
  // }, []);
  const { address, chainId, chain } = useAccount();
  useEffect(() => {
    if (address) {
      Sentry.setUser({
        id: address.toString(),
        chainId: chainId,
        buildVersion: process.env.APP_VERSION,
      });
    }
  }, [address, chainId]);
  useGetURLRef();
  const navigate = useNavigate();
  const resetRoomStore = useRoomStore((state) => state.resetStore);
  useAccountEffect({
    onConnect(data) {
      const alreadyConnected = sessionStorage.getItem('walletConnected');
      if (!alreadyConnected) {
        const startWithZeroX = data.address.startsWith('0x');
        const walletWithoutPrefix = startWithZeroX
          ? data.address.substring(2)
          : data.address;
        // const eventOptions = {
        //   category: 'game',
        //   action: 'Wallet connect',
        //   wallet: walletWithoutPrefix,
        //   startWithZeroX: startWithZeroX,
        // };
        // ReactGA.event('login', eventOptions);
        // ReactPixel.track('AddToCart');
        // if (window.twq) {
        //   window.twq('event', 'tw-op1jf-op1jl', {
        //     conversion_id: null,
        //   });
        // }
      }

      sessionStorage.setItem('walletConnected', 'true');
    },
    onDisconnect() {
      resetRoomStore();
      window.location.reload();
    },
  });

  const {
    setAvailableCurrencies,
    setCurrenciesForSelect,
    setSelectedCurrency,
    userBets,
  } = useCurrenciesStore();
  useEffect(() => {
    if (!chain) return;
    const currencies = getAvailableCurrencies(chain as Chain).map((c) => ({
      ...c,
      address: (c.address as string).toLowerCase() as `0x${string}`,
      chainId: Number(c.chainId),
      decimals: Number(c.decimals),
      symbol: String(c.symbol),
      bet: Array.isArray(c.bet) ? c.bet.map(String) : [String(c.bet)],
      minBet: Number(c.minBet),
    }));

    const currencyForSelect = currencies.map((currency) => ({
      value: String(currency.symbol),
      label: String(currency.symbol),
      address: currency.address,
    }));
    setCurrenciesForSelect(currencyForSelect);
    setAvailableCurrencies(currencies);
    let betKey;
    if (chainId && userBets && userBets[chainId]) {
      betKey = Object.keys(userBets[chainId])[0];
    }
    if (betKey) {
      setSelectedCurrency(betKey);
    } else {
      setSelectedCurrency(currencies[0].symbol);
    }
  }, [chain]);
  useFindGames();
  return (
    <GridBackground>
      <Suspense
        fallback={<div className="h-screen w-full bg-dark-background" />}
      >
        <ErrorBoundary>
          <Routes>
            <Route element={<ConnectPage />} path={ROUTES.HOME} />
            <Route element={<Room />} path={`${ROUTES.ROOM}/:networkId/:activeRoomId?`} />
            <Route element={<LobbyPage />} path={ROUTES.LOBBY} />
            <Route element={<StreamRoom />} path={`${ROUTES.STREAM}/:networkId/:activeRoomId`} />
            <Route element={<div />} path={ROUTES.API} />
            <Route path="*" element={<Navigate to={ROUTES.HOME} />} />
            <Route element={<ConnectWallet />} path={'test'} />
          </Routes>
        </ErrorBoundary>
      </Suspense>
    </GridBackground>
  );
};

export default App;
