import React, { useEffect, useState } from 'react';
import TextWithLeftColorLine from 'shared/components/TextWithLeftColorLine/TextWithLeftColorLine';
import useMediaQuery from 'shared/hooks/useMediaQuery';
import { useRevealStatusText } from './use-reveal-status-text';
import AnimatedTransition from 'shared/components/AnimatedTransition/AnimatedTransition';
import MintingLoader from 'shared/components/Loaders/MintingLoader/MintingLoader';
import {
  technicalDefeatMessages,
  waitYourRevealMessages,
} from 'shared/constants/text-loaders';
import { useAccount } from 'wagmi';
import { usePlayerGamesStore } from 'shared/store/playerGamesStore';
import { useParams } from 'react-router-dom';
import { useRoomStore } from 'shared/store/roomStore';
import { useReveal } from 'modules/Room/Hooks/useReveal';
import PrimaryButton from 'shared/components/PrimaryButton/PrimaryButton';
import { SHOW_REVEAL_BTN } from 'shared/constants/timers';
import DigitalClock from '@/shared/components/DigitalClock/DigitalClock';
import { epochToLocalUTC } from '@/shared/utils/time';
import { useTimerLogic } from '../../hooks/use-timer-logic';
import { useGameStatusStore } from '@/modules/Room/game-status/store/game-status-store';

export const WaitYourRevealStatus = () => {
  const [showTimer, setShowTimer] = useState(false);
  const [showCloseButton, setShowCloseButton] = useState(false);
  const [technicalDefeatError, setTechnicalDefeatError] = useState(false);
  const [showRevealBtn, setShowRevealBtn] = useState(false);
  const { revealGameStatus, setRevealGameStatusError } = useGameStatusStore();
  const { 
    isSuccess,
    closeUnusedRoomHandler,
    onExpire
  } = useTimerLogic({
    selector: 'player-card-host',
    gameStatusName: "waitYourReveal",
    setShowTimer,
    setShowCloseButton,
    setTechnicalDefeatError
  });
  
  useEffect(() => {
    return () => {
      setRevealGameStatusError({ error: false, reject: false });
    };
  }, [setRevealGameStatusError]);
  useEffect(() => {
    setTimeout(() => {
      setShowRevealBtn(true);
    }, SHOW_REVEAL_BTN);
  }, []);
  const { getTextComponent } = useRevealStatusText(
    showTimer,
    showCloseButton,
    technicalDefeatError
  );
  const { chainId } = useAccount();
  const { closeHash, playMoves } = usePlayerGamesStore();
  const { activeRoomId } = useParams();
  const { address } = useAccount();
  const { roundCounter, closerRoom } = useRoomStore();
  const { writeReveal } = useReveal();

  return (
    <div className="sm:w-full p-[20px] sm:p-[30px] border border-border rounded-[12px] sm:rounded-[0] sm:border-0 bg-grape sm:bg-[transparent]">
      <AnimatedTransition
        show={
          !!closeHash[chainId!]?.hash ||
          !!playMoves[activeRoomId! + address! + chainId + roundCounter]
            ?.revealHash?.hash
        }
      >
        <MintingLoader
          className="absolute top-2/3 sm:top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
          hash={
            closeHash[chainId!]?.hash ||
            playMoves[activeRoomId! + address! + chainId + roundCounter]
              ?.revealHash?.hash
          }
          selector=".player-card-host"
          messages={
            !!closeHash[chainId!]?.hash
              ? technicalDefeatMessages
              : waitYourRevealMessages
          }
        />
      </AnimatedTransition>
      <AnimatedTransition
        show={
          !(
            !!closeHash[chainId!]?.hash ||
            !!playMoves[activeRoomId! + address! + chainId + roundCounter]
              ?.revealHash?.hash
          )
        }
      >
        {getTextComponent()}
        <AnimatedTransition show={showRevealBtn}>
          <div className="flex justify-center">
            <PrimaryButton
              text={'common.reveal'}
              onClick={writeReveal}
              className={'mt-[20px]'}
            />
          </div>
        </AnimatedTransition>

        <AnimatedTransition show={showCloseButton && !isSuccess}>
        <div className="flex justify-center mt-[20px]">
          <PrimaryButton
            text={'common.technicalDefeat'}
            onClick={closeUnusedRoomHandler}
          />
        </div>
      </AnimatedTransition>
        <AnimatedTransition show={showTimer && !showCloseButton}>
          <div className='w-full flex justify-center mt-[24px]'>
            <DigitalClock
              key={(epochToLocalUTC(closerRoom?.deadline) as Date).toString()}
              expiryTimestamp={epochToLocalUTC(closerRoom?.deadline)}
              className='w-fit'
              onExpire={onExpire}
            />
          </div>
        </AnimatedTransition>
      </AnimatedTransition>
    </div>
  );
};
