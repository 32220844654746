import React, { Fragment, useEffect, useState, useRef } from 'react';
import { ModalPositionEnum } from './constants';
import GridBackground from 'shared/components/GridBackground/GridBackground';
import { Transition } from '@headlessui/react';
import { createPortal } from 'react-dom';
import './Modal.scss';
import { useShowSignModal } from 'shared/hooks/useShowSignModal';

interface Props {
  open: boolean;
  children: React.ReactNode;
  position?: ModalPositionEnum;
  className?: string;
  showFrameLoader?: boolean;
}

const ModalWithFullScreenBg = ({
  open,
  children,
  className,
  position = ModalPositionEnum.CENTER,
  showFrameLoader,
}: Props) => {
  const [minHeight, setMinHeight] = useState<string | number>('auto');
  const innerDivRef = useRef<HTMLDivElement>(null);
  // remove from here, after modals refactoring
  const { showSignModal } = useShowSignModal();
  useEffect(() => {
    if (open && innerDivRef.current) {
      const height = innerDivRef.current.offsetHeight;
      let value = height + 120;
      setMinHeight(`${value < 570 ? 570 : value}px`);
    }
  }, [open, children]);

  return createPortal(
    <Transition appear show={open && !showSignModal} as={Fragment}>
      <div
        className={`absolute grid grid-cols-1 place-items-center top-0 bottom-0 left-0 right-0 z-20 ${className}`}
        style={{ minHeight }}
      >
        <div className="sm:max-w-[1800px] h-[20px] relative modal">
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              ref={innerDivRef}
              className={`absolute border-[2px] sm:border-[3px] border-border rounded-[4px] sm:rounded-[14px] z-40 top-1/2 transform -translate-y-1/2 sm:min-w-min 
              ${
                position === ModalPositionEnum.LEFT
                  ? 'modal__left sm:w-[32%] w-[100%]'
                  : ''
              } 
              ${
                position === ModalPositionEnum.RIGHT
                  ? 'modal__right sm:w-[32%]'
                  : ''
              } ${
                position === ModalPositionEnum.CENTER
                  ? 'top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[95%] sm:w-[450px]'
                  : ''
              }
              ${showFrameLoader ? 'frameLoader' : ''}
              `}
            >
              <GridBackground className="rounded-[4px] sm:rounded-[10px]">
                <div>{children}</div>
              </GridBackground>
            </div>
          </Transition.Child>
        </div>
      </div>
    </Transition>,
    document.body
  );
};

export default ModalWithFullScreenBg;
