import { useAccount, useDisconnect, useEnsAvatar, useEnsName, usePublicClient } from 'wagmi';
import { useEffect } from 'react';

export function Account() {
  const { address, connector, chainId } = useAccount();
  const { disconnect } = useDisconnect();
  const { data: ensName } = useEnsName({ address });
  const { data: ensAvatar } = useEnsAvatar({ name: ensName! });
  const publicClient = usePublicClient();

  useEffect(() => {

    console.log('Public client:', publicClient);
  }, [publicClient]);

  const formattedAddress = formatAddress(address);
  return (
    <div className="row text-white">
      <div className="inline">
        {ensAvatar ? (
          <img alt="ENS Avatar" className="avatar" src={ensAvatar} />
        ) : (
          <div className="avatar" />
        )}
        <div className="stack">
          {address && (
            <div className="text">
              {ensName ? `${ensName} (${formattedAddress})` : formattedAddress}
            </div>
          )}
          <div className="subtext">
            Connected to {connector?.name} Connector
          </div>
        </div>
      </div>
      <a className="block" href="lobby">
        Lobby
      </a>
      <a className="block" href="stream/137/22">
        stream/137/22
      </a>
      <a className="block" href="stream/42161/860">
        stream/42161/860
      </a>
      <p>current chain is {chainId}</p>
      <p>rpc : {publicClient?.chain?.rpcUrls?.default && JSON.stringify(publicClient.chain.rpcUrls.default)}</p>
      <button className="button" onClick={() => disconnect()} type="button">
        Disconnect
      </button>
    </div>
  );
}

function formatAddress(address?: string) {
  if (!address) return null;
  return `${address.slice(0, 6)}…${address.slice(38, 42)}`;
}
