import { useEffect, useState } from 'react';
import { useWriteRspContractCloseRoom } from 'shared/features/contract.abi';
import { useParams } from 'react-router-dom';
import { useAccount } from 'wagmi';
import { useTransactionReceipt } from 'shared/hooks/useTransactionReceipt';
import { useRoomStore } from 'shared/store/roomStore';
import { usePlayerGamesStore } from 'shared/store/playerGamesStore';
import { estimateGasForFunction } from 'shared/utils/estimateGas';
import { useGameStatusStore } from '../store/game-status-store';
import { GameStatusEnum } from '../constants/game-status-enum';

export const useCloseUnusedRoom = () => {
  const { chainId } = useAccount();
  const [localHash, setLocalHash] = useState<`0x${string}`>();
  const { data, writeContractAsync, error, isSuccess, isError } =
    useWriteRspContractCloseRoom();

  const { setGameStatus } = useGameStatusStore();
  const { setCloseHash, closeHash } = usePlayerGamesStore();
  useEffect(() => {
    if (isSuccess) {
      const now = new Date();
      const timeString = now.toLocaleTimeString();
      setCloseHash(chainId!, data!, now);
      console.log('user approved technical defeat', timeString);
    }
  }, [isSuccess]);
  useEffect(() => {
    if (chainId && closeHash[chainId!]?.hash) {
      setLocalHash(closeHash[chainId!]!.hash);
    }
  }, []);
  const { activeRoomId } = useParams();
  const { setTransactionCallBack } = usePlayerGamesStore();

  const closeUnusedRoomHandler = () => {
    setTransactionCallBack(async () => {
      let gas = await estimateGasForFunction('closeRoom', [
        BigInt(activeRoomId || 0),
      ]);
      writeContractAsync({
        args: [BigInt(activeRoomId || 0)],
        gas,
      });
    });
    writeContractAsync({
      args: [BigInt(activeRoomId || 0)],
    });
  };

  const {
    isSuccess: isSuccessCloseUnusedRoom,
    error: transactionError,
    isError: transactionIsError,
    limitExceed,
  } = useTransactionReceipt(localHash, closeHash?.[chainId!]?.date);

  useEffect(() => {
    if (limitExceed) {
      console.log('limit exceed useCLoseUnusedRoom');
      setGameStatus(GameStatusEnum.TRANSACTION_TAKE_TO_LONG, true);
    }
  }, [limitExceed]);

  useEffect(() => {
    if (isSuccessCloseUnusedRoom) {
      const now = new Date();
      const timeString = now.toLocaleTimeString();
      console.log('Close unused room receipt', timeString);
      setCloseHash(chainId!, null);
      useRoomStore.getState().refetchRoom();
    }
  }, [isSuccessCloseUnusedRoom]);

  useEffect(() => {
    if (error || transactionIsError) {
      console.log('useCloseUnusedRoom', error);
      setCloseHash(chainId!, null);
    }
  }, [error, transactionIsError]);

  return {
    closeUnusedRoomHandler,
    isSuccess,
    technicalDefeatError: isError && transactionIsError,
  };
};
