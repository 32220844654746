import React, { useEffect, useState } from 'react';
import PrimaryButton from 'shared/components/PrimaryButton/PrimaryButton';
import { useNavigate, useParams } from 'react-router-dom';
import { useTimersStore } from 'shared/store/timersStore';
import { useRoomStore } from 'shared/store/roomStore';
import { ROUTES } from 'shared/constants';
import { useAccount } from 'wagmi';
import { usePlayerGamesStore } from 'shared/store/playerGamesStore';
// import ReactGA from 'react-ga4';
import { useGlobalStore } from 'shared/store/globalStore';
import TextWithLeftColorLine from 'shared/components/TextWithLeftColorLine/TextWithLeftColorLine';
import { useTranslation } from 'react-i18next';
import { FinishStateEnum } from 'modules/Room/constants/enums';
import {
  RoomStatusEnum,
  WinnerStatusEnum,
} from 'shared/constants/shared-enums';
import { stateMapping } from './stetMapping';
import GridBackground from 'shared/components/GridBackground/GridBackground';
import { useGameStatusStore } from '../../game-status/store/game-status-store';
// import ReactPixel from 'react-facebook-pixel';

const GameFinishViews = () => {
  const { activeRoomId } = useParams();
  const { address, chainId } = useAccount();
  const { playerA, playerB, roomStatus, winnerStatus, amIPlayerA } =
    useRoomStore();
  const { removeActiveGame } = useGlobalStore();
  const { t } = useTranslation();
  const [viewState, setViewState] = useState<FinishStateEnum | null>(null);
  const navigate = useNavigate();
  const { resetGameStatus } = useGameStatusStore();
  useEffect(() => {
    resetGameStatus();
  }, []);

  useEffect(() => {
    const key = `${activeRoomId}${address}${chainId}`;
    useTimersStore.getState().deleteAllTimersForThisKey(key);
    usePlayerGamesStore.getState().deletePlayMove(key);

    if (
      playerA.address === address?.toLowerCase() ||
      playerB.address === address?.toLowerCase()
    ) {
      const startWithZeroX = address.startsWith('0x');
      const walletWithoutPrefix = startWithZeroX
        ? address.substring(2)
        : address;
      // ReactGA.event('close', {
      //   category: 'game',
      //   action: 'Finish Game',
      //   wallet: walletWithoutPrefix,
      //   startWithZeroX: startWithZeroX,
      //   chainId: chainId,
      //   roomId: activeRoomId,
      // });
      // ReactPixel.track('Purchase');
      // if (window.twq) {
      //   window.twq('event', 'tw-op1jf-op1k2', {
      //     conversion_id: null,
      //   });
      // }
    }

    removeActiveGame(`${chainId}${address?.toLowerCase()}`);
  }, [
    activeRoomId,
    address,
    chainId,
    playerA.address,
    playerB.address,
    removeActiveGame,
  ]);

  useEffect(() => {
    if (winnerStatus && roomStatus === RoomStatusEnum.Closed && activeRoomId) {
      switch (winnerStatus) {
        case WinnerStatusEnum.WinnerPlayerA:
          setViewState(
            amIPlayerA ? FinishStateEnum.YouWin : FinishStateEnum.YouLost
          );
          break;
        case WinnerStatusEnum.WinnerPlayerB:
          setViewState(
            amIPlayerA ? FinishStateEnum.YouLost : FinishStateEnum.YouWin
          );
          break;
        case WinnerStatusEnum.TechnicalWinnerA:
          setViewState(
            amIPlayerA
              ? FinishStateEnum.YouTechnicallyWin
              : FinishStateEnum.YouTechnicallyLost
          );
          break;
        case WinnerStatusEnum.TechnicalWinnerB:
          setViewState(
            amIPlayerA
              ? FinishStateEnum.YouTechnicallyLost
              : FinishStateEnum.YouTechnicallyWin
          );
          break;
        case WinnerStatusEnum.PlayerAExitRoom:
          setViewState(FinishStateEnum.YouExitRoom);
          break;
        case WinnerStatusEnum.UnusedRoomClosed:
          setViewState(FinishStateEnum.NoOneWin);
          break;
        default:
          setViewState(null);
      }
    }
  }, [winnerStatus, roomStatus, activeRoomId, amIPlayerA]);

  const startNewGameHandler = () => {
    removeActiveGame(`${chainId}${address?.toLowerCase()}`);
    useRoomStore.getState()?.resetStore();
    navigate(ROUTES.LOBBY);
  };

  const textGenerator = () => {
    if (!viewState) return null;

    const content = stateMapping[viewState];
    return (
      <div className="mb-[20px]">
        {content.texts.map((text, index) => (
          <p
            key={text}
            className={`text-white text-[12px] sm:text-[17px] text-justify ${
              index !== content.texts.length - 1 ? 'mb-[20px]' : ''
            }`}
          >
            {t(text)}
          </p>
        ))}
      </div>
    );
  };

  if (!viewState) return null;

  return (
    <div className="sm:w-full p-[20px] sm:p-[30px] border border-border rounded-[12px] sm:rounded-[0] sm:border-0 bg-grape sm:bg-[transparent]">
      <div className="p-[20px] sm:p-[30px] w-full">
        {textGenerator()}
        <div className="flex justify-center">
          <PrimaryButton
            text={t('common.selectNewBet')}
            onClick={startNewGameHandler}
          />
        </div>
      </div>
    </div>
  );
};

export default GameFinishViews;
