import { useEffect } from 'react';
import { GameStatusEnum } from '../../constants/game-status-enum';
import { useGameStatusStore } from '../../store/game-status-store';
import { useRoomStore } from 'shared/store/roomStore';
import {
  RoundStagesEnum,
  RoomStatusEnum,
  CloserEnum,
  MovesEnum,
} from 'shared/constants/shared-enums';
import AnimatedTransition from 'shared/components/AnimatedTransition/AnimatedTransition';
import { NULL_ADDRESS, NULL_MOVE } from 'shared/constants/constants';
import { useParams } from 'react-router-dom';
import { useAccount } from 'wagmi';
import { JoinGameModal } from '../modals';
import { useShowSignModal } from 'shared/hooks/useShowSignModal';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'shared/constants/routes';
import {
  WaitYourRevealStatus,
  WatchGameStatus,
  WaitYourMoveStatus,
  WaitYourApproveMoveStatus,
  WaitOpponentMoveStatus,
  WaitOpponentRevealStatus,
  
} from './statuses';
import { CardPlayerEnum } from '@/modules/Room/constants/enums';

interface PlayGameStatusesProps {
  player?: CardPlayerEnum;
}

export const PlayGameStatuses = ({ player }: PlayGameStatusesProps) => {
  const { gameStatus, setGameStatus, resetGameStatus } = useGameStatusStore();
  const navigate = useNavigate();
  const {
    playerA,
    playerB,
    roundStage,
    roomStatus,
    closerRoom,
    roundMoves,
    amIPlayerA,
    winnerStatus,
    someoneElseGame,
    showHash
  } = useRoomStore();
  const { activeRoomId, networkId } = useParams();
  const { address } = useAccount();
  useEffect(() => {
    if (
      playerA.address !== NULL_ADDRESS &&
      playerA.address !== address?.toLowerCase() &&
      playerB.address !== NULL_ADDRESS &&
      playerB.address !== address?.toLowerCase()
    ) {
      console.log('watch game');
      navigate(`${ROUTES.STREAM}/${networkId}/${activeRoomId}`)
      resetGameStatus();
      setGameStatus(GameStatusEnum.WATCH_GAME, true);
    } else if (
      activeRoomId &&
      playerA.address !== NULL_ADDRESS &&
      playerA.address !== address?.toLowerCase() &&
      playerB.address === NULL_ADDRESS &&
      !gameStatus[GameStatusEnum.CONNECTING_TO_PLAYER] &&
      roomStatus !== RoomStatusEnum.Closed
    ) {
      console.log('join game');
      resetGameStatus();
      setGameStatus(GameStatusEnum.JOIN_GAME, true);
    } else if (
      (roomStatus === RoomStatusEnum.ReadyForGame ||
        roomStatus === RoomStatusEnum.ReadyForReveal) &&
      activeRoomId
    ) {
      if (
        (roundStage === RoundStagesEnum.None ||
          roundStage === RoundStagesEnum.InitGame) &&
        roomStatus === RoomStatusEnum.ReadyForGame
      ) {
        console.log('round stage', roundStage);
        console.log('start game');
        if (
          !gameStatus[GameStatusEnum.WAIT_YOUR_MOVE] &&
          !gameStatus[GameStatusEnum.WAIT_YOUR_APPROVE]
        ) {
          resetGameStatus();
          setGameStatus(GameStatusEnum.WAIT_YOUR_MOVE, true);
        }
      } else if (
        roundStage === RoundStagesEnum.AddMove &&
        roomStatus === RoomStatusEnum.ReadyForGame
      ) {
        console.log('round stage', roundStage);
        console.log('one player made a move');
        if (
          (amIPlayerA &&
            closerRoom.closer === CloserEnum.PlayerA &&
            roundMoves.playerB.encrMove === NULL_MOVE) ||
          (!amIPlayerA &&
            closerRoom.closer === CloserEnum.PlayerB &&
            roundMoves.playerA.encrMove === NULL_MOVE)
        ) {
          resetGameStatus();
          setGameStatus(GameStatusEnum.WAIT_OPPONENT_MOVE, true);
        } else if (
          ((amIPlayerA && roundMoves.playerA.encrMove === NULL_MOVE) ||
            (!amIPlayerA && roundMoves.playerB.encrMove === NULL_MOVE)) &&
          !gameStatus[GameStatusEnum.WAIT_YOUR_MOVE] &&
          !gameStatus[GameStatusEnum.WAIT_YOUR_APPROVE]
        ) {
          resetGameStatus();
          setGameStatus(GameStatusEnum.WAIT_YOUR_MOVE, true);
        }
      } else if (
        roundStage === RoundStagesEnum.RevealMove &&
        roomStatus === RoomStatusEnum.ReadyForReveal
      ) {
        console.log('round stage', roundStage);
        console.log('no one make reveal or one make reveal');
        if (
          (roundMoves.playerA.move === MovesEnum.None &&
            roundMoves.playerB.move === MovesEnum.None) ||
          (amIPlayerA && roundMoves.playerA.move === MovesEnum.None) ||
          (!amIPlayerA && roundMoves.playerB.move === MovesEnum.None)
        ) {
          resetGameStatus();
          setGameStatus(GameStatusEnum.WAIT_YOUR_REVEAL, true);
        } else if (
          (amIPlayerA &&
            closerRoom.closer === CloserEnum.PlayerA &&
            roundMoves.playerB.move === MovesEnum.None) ||
          (!amIPlayerA &&
            closerRoom.closer === CloserEnum.PlayerB &&
            roundMoves.playerA.move === MovesEnum.None &&
            !gameStatus[GameStatusEnum.WAIT_OPPONENT_REVEAL])
        ) {
          resetGameStatus();
          setGameStatus(GameStatusEnum.WAIT_OPPONENT_REVEAL, true);
        }
      }
    } else if (
      winnerStatus &&
      roomStatus === RoomStatusEnum.Closed &&
      activeRoomId &&
      someoneElseGame
    ) {
      resetGameStatus();
      setGameStatus(GameStatusEnum.WATCH_GAME, true);
    }
  }, [
    playerA,
    playerB,
    address,
    roomStatus,
    closerRoom,
    roundStage,
    roundMoves,
    amIPlayerA,
    activeRoomId,
    winnerStatus,
    someoneElseGame,
  ]);
  
  const { showSignModal } = useShowSignModal();

  if (gameStatus[GameStatusEnum.WAIT_YOUR_MOVE] && !showSignModal && !showHash && player === CardPlayerEnum.Player) {
    return  <WaitYourMoveStatus />
  }

  if (gameStatus[GameStatusEnum.WATCH_GAME] && !showSignModal && player === CardPlayerEnum.Player) {
    return <WatchGameStatus />
  }

  if (gameStatus[GameStatusEnum.WAIT_OPPONENT_MOVE] && !showSignModal && !showHash && player === CardPlayerEnum.Player) {
    return <WaitOpponentMoveStatus />
  }

  if (gameStatus[GameStatusEnum.WAIT_YOUR_REVEAL] && !showSignModal && !showHash && player === CardPlayerEnum.Player) {
    return <WaitYourRevealStatus />
  }

  if (gameStatus[GameStatusEnum.WAIT_OPPONENT_REVEAL] && !showSignModal && !showHash && player === CardPlayerEnum.Player) {
    return <WaitOpponentRevealStatus />
  }

  if (gameStatus[GameStatusEnum.WAIT_YOUR_APPROVE] && !showSignModal && !showHash && player === CardPlayerEnum.Player) {
    return <WaitYourApproveMoveStatus />
  }

  if (gameStatus[GameStatusEnum.JOIN_GAME]) {
    return (
      <AnimatedTransition show={gameStatus[GameStatusEnum.JOIN_GAME]}>
        <JoinGameModal open={gameStatus[GameStatusEnum.JOIN_GAME]} />
      </AnimatedTransition>
    )
  }

  return null;
};
