import GuysLoader from 'shared/components/Loaders/GuysLoader/GuysLoader';
import { usePlayerGamesStore } from 'shared/store/playerGamesStore';
import { useAccount } from 'wagmi';
import { connectingGameMessages } from 'shared/constants/text-loaders';

export const ConnectingToOpponentLoaderStatus = () => {
  const { joinHash } = usePlayerGamesStore();
  const { chainId } = useAccount();
  return (
    <GuysLoader
      className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
      messages={connectingGameMessages}
      hash={joinHash[chainId!]?.hash}
    />
  );
};
