import { useEffect, useState } from 'react';
import { useWriteRspContractInitRoom } from 'shared/features/contract.abi';
import { useTransactionReceipt } from 'shared/hooks/useTransactionReceipt';
import { useRoomStore } from 'shared/store/roomStore';
import { WriteError } from 'shared/types/app-types';
import { usePlayerGamesStore } from 'shared/store/playerGamesStore';
import { useAccount } from 'wagmi';
import * as Sentry from '@sentry/react';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from 'shared/constants';
import { estimateGasForFunction } from 'shared/utils/estimateGas';
import { useGameStatusStore } from '../game-status/store/game-status-store';
import { GameStatusEnum } from '../game-status/constants/game-status-enum';

const logPostion = 2;
const roomPositionIndex = 1;

const useInitGame = () => {
  const navigate = useNavigate();
  const { chainId } = useAccount();
  const [localHash, setLocalHash] = useState<`0x${string}`>();
  const { setStartHash, startHash } = usePlayerGamesStore();
  const { setIsGameSearching } = useRoomStore();
  const { setGameStatus, setGameBetGameStatus, resetGameStatus } =
    useGameStatusStore();
  const { setTransactionCallBack } = usePlayerGamesStore();
  const {
    data: writeInitRoomData,
    writeContractAsync,
    isSuccess,
    isError,
    error,
  } = useWriteRspContractInitRoom();
  useEffect(() => {
    if (chainId && startHash[chainId]?.hash)
      setLocalHash(startHash[chainId]!.hash);
  }, [startHash]);

  useEffect(() => {
    if (isSuccess) {
      const now = new Date();
      const timeString = now.toLocaleTimeString();
      console.log('user approved start', timeString);
      setStartHash(chainId!, writeInitRoomData!, now);
      setLocalHash(writeInitRoomData!);
    }
  }, [isSuccess]);

  const {
    data: receiptData,
    isSuccess: isSuccessInitRoom,
    error: transactionError,
    limitExceed,
  } = useTransactionReceipt(localHash, startHash?.[chainId!]?.date);
  const { activeRoomId } = useParams();
  useEffect(() => {
    if (
      receiptData?.logs?.[logPostion]?.topics?.[roomPositionIndex] &&
      !activeRoomId
    ) {
      let hexString =
        receiptData.logs[logPostion].topics[roomPositionIndex].slice(2);
      let parsed = parseInt(hexString, 16);
      if (Number.isFinite(parsed) && Number.isSafeInteger(parsed)) {
        navigate(`${ROUTES.ROOM}/${chainId}/${parsed}`);
        setLocalHash(undefined);
        setStartHash(chainId!, null, undefined);
      } else {
        window.location.reload();
      }
    }
  }, [receiptData, activeRoomId]);

  useEffect(() => {
    if (limitExceed) {
      console.log('limit exceed useInitGame');
      setGameStatus(GameStatusEnum.TRANSACTION_TAKE_TO_LONG, true);
    }
  }, [limitExceed]);

  useEffect(() => {
    if (isSuccessInitRoom) {
      const now = new Date();
      const timeString = now.toLocaleTimeString();
      setIsGameSearching(false);
      console.log('Start new game', timeString);
      setGameBetGameStatus({ reject: false, error: false });
      setStartHash(chainId!, null);
    }
  }, [isSuccessInitRoom]);

  useEffect(() => {
    if (error || transactionError || isError) {
      console.log('use init game error', error);
      console.log(error);
      console.log('transactionError', transactionError);
      setStartHash(chainId!, null);
      if (error) {
        const typedError = error as WriteError | undefined;
        if (typedError?.cause?.code) {
          if (typedError.cause.code !== 4001) {
            Sentry.captureException(error);
          }
          setGameBetGameStatus({
            reject: typedError.cause.code === 4001,
            error: typedError.cause.code !== 4001,
          });
        } else {
          setGameBetGameStatus({
            reject: false,
            error: true,
          });
        }
      }
      if (transactionError) Sentry.captureException(transactionError);
      resetGameStatus();
      // setModalState(ModalTypeEnum.SET_BET, true);
      navigate(ROUTES.LOBBY);
      setIsGameSearching(false);
    }
  }, [error, transactionError, isError]);

  return async (args: any) => {
    const gas = await estimateGasForFunction('initRoom', args.args);
    try {
      await writeContractAsync({ ...args, gas });
      setTransactionCallBack(async () => {
        const gas = await estimateGasForFunction('initRoom', args);
        writeContractAsync({ ...args, gas });
      });
    } catch (error) {
      console.log('writeContract error', error);
      resetGameStatus();
      setGameBetGameStatus({ reject: false, error: true });
      // setModalState(ModalTypeEnum.SET_BET, true);
      navigate(ROUTES.LOBBY);
    }
  };
};

export default useInitGame;
