import React from 'react';
import { Avatar } from 'shared/components/Avatar/Avatar';
import { ROUTES } from 'shared/constants';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useBalance } from 'shared/hooks/useBalance';
import { CurrencyType } from 'shared/web3';
import { useAccount } from 'wagmi';
import { waitForTransactionReceipt } from '@wagmi/core';
import { useAllowance } from 'modules/Room/Hooks/useAllowance';
import { NULL_ADDRESS } from 'shared/constants/constants';
import { useWriteRspContractJoinRoom } from 'shared/features/contract.abi';
import { usePlayerGamesStore } from 'shared/store/playerGamesStore';
import { estimateGasForFunction } from 'shared/utils/estimateGas';
import OrangeArrowIcon from 'assets/orangeArrow.svg?react';
import { config } from 'shared/config';
import { coinIcons } from 'shared/constants';

import './styles.scss';
import Approve from 'modules/Room/features/Allowance/Approve';
import { useTranslation } from 'react-i18next';
import useMediaQuery from 'shared/hooks/useMediaQuery';

interface LiveGameItemProps {
  roomId: BigInt;
  address: string;
  currency?: CurrencyType;
  amount: number;
  isJoinRoomGlobalLoading: boolean;
  onSetIsJoinRoomGlobalLoading: (value: boolean) => void;
  onJoinRoomCallback: () => void;
}

const spenderAddress = import.meta.env.VITE_RSP_CONTRACT_ADDRESS;

export const LiveGameItemMobile = ({
  roomId,
  address,
  currency,
  amount,
  isJoinRoomGlobalLoading,
  onSetIsJoinRoomGlobalLoading,
  onJoinRoomCallback,
}: LiveGameItemProps) => {
  const [isJoinRoomLoading, setIsJoinRoomLoading] = useState(false);
  const [isItemHovered, setIsItemHovered] = useState(false);
  const { setJoinHash, setTransactionCallBack } = usePlayerGamesStore();
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [approve, setApprove] = useState(false);
  const navigate = useNavigate();
  const { address: userAddress, chainId } = useAccount();
  const { t } = useTranslation();
  const isTablet = useMediaQuery('(min-width: 768px)');

  const { data: allowance } = useAllowance({
    tokenAddress: currency!.address,
    spenderAddress,
    ownerAddress: userAddress || NULL_ADDRESS,
  });

  const {
    writeContractAsync,
    isSuccess,
    data: joinRoomData,
  } = useWriteRspContractJoinRoom();

  const handleSuccessJoinToRoom = async (joinRoomData: `0x${string}`) => {
    const now = new Date();
    setJoinHash(chainId!, joinRoomData!, now);

    try {
      await waitForTransactionReceipt(config, {
        hash: joinRoomData,
      });

      navigate(`${ROUTES.ROOM}/${chainId}/${Number(roomId)}`);
      setJoinHash(chainId!, null, undefined);
    } catch (e) {
      setIsJoinRoomLoading(false);
      onSetIsJoinRoomGlobalLoading(false);
      handleShowError(t('pages.liveGamesSection.joinRoomError'));
    }
  };

  useEffect(() => {
    if (isSuccess && joinRoomData) {
      onJoinRoomCallback();
      handleSuccessJoinToRoom(joinRoomData);
    }
  }, [isSuccess]);

  const { balance } = useBalance({
    currencyAddress: currency?.address,
    walletAddress: userAddress as `0x${string}`,
  });

  const handleShowError = (errorMessage: string) => {
    setIsError(true);
    setErrorMessage(errorMessage);

    setTimeout(() => {
      setIsItemHovered(false);
      setIsError(false);
    }, 2000);
  };

  const handleContinueJoinGame = async () => {
    try {
      const gas = await estimateGasForFunction('joinRoom', [roomId, address]);
      // @ts-ignore:next-line
      await writeContractAsync({ args: [roomId, address], gas });
      setTransactionCallBack(async () => {
        const gas = await estimateGasForFunction('joinRoom', [roomId, address]);
        // @ts-ignore:next-line
        await writeContractAsync({ args: [roomId, address], gas });
      });

      onSetIsJoinRoomGlobalLoading(false);
    } catch (error) {
      console.log('writeContract error', error);
      setIsJoinRoomLoading(false);

      handleShowError(t('pages.liveGamesSection.joinRoomError'));
      onSetIsJoinRoomGlobalLoading(false);

      return;
    }

    setApprove(false);
    setIsItemHovered(false);
    setIsJoinRoomLoading(false);
    onSetIsJoinRoomGlobalLoading(false);
  };

  const handleJoinGame = async () => {
    if (isError || isJoinRoomGlobalLoading) {
      return;
    }

    onSetIsJoinRoomGlobalLoading(true);

    if (!balance || balance < amount) {
      handleShowError(t('pages.liveGamesSection.lowBalanceError'));
      onSetIsJoinRoomGlobalLoading(false);

      return;
    }

    setIsJoinRoomLoading(true);
    setIsItemHovered(true);

    if (Number(allowance) <= amount * 10 ** currency!.decimals) {
      setApprove(true);

      return;
    }

    handleContinueJoinGame();
  };

  const handleShowJoinButton = () => {
    if (isJoinRoomGlobalLoading || !isTablet) {
      return;
    }

    setIsItemHovered(true);
  };

  const handleHideJoinButton = () => {
    if (isJoinRoomLoading || isError) {
      return;
    }
    setIsItemHovered(false);
  };

  return (
    <div
      className="w-full"
      onMouseEnter={handleShowJoinButton}
      onMouseLeave={handleHideJoinButton}
    >
      <div
        className={`grid grid-cols-[1.2fr_0.8fr_0.8fr_1fr]  border-[1px] sm:border-[2px] border-[#FFFFFF40] p-2 bg-gradient-to-r from-[#05055B] to-[#151552] rounded-[6px] sm:rounded-[12px] transition-all  ${
          isJoinRoomGlobalLoading ? 'cursor-not-allowed' : ''
        }`}
      >
        <div className="flex items-center items gap-[12px]">
          <Avatar
            userAddress={`0x${address}`}
            className={`w-[24px] h-[24px] rounded-[4px] sm:rounded-[12px]`}
          />
          <div className="flex flex-col">
            <span className="text-[#FFA700]  text-[12px] font-medium">
              {address.substring(0, 2)}...
              {address.substring(address.length - 4)}
            </span>
            <span className="text-[#F7F7F7] text-[10px] font-medium opacity-75">
              {t('pages.liveGamesSection.id')} {roomId.toString()}
            </span>
          </div>
        </div>
        {/* <div className="text-[#F7F7F7] font-semibold flex items-center sm:text-[20px] text-[10px] col-span-2 flex gap-2 items-center">
          1 mins ago
        </div> */}
        <div className="text-[#F7F7F7] font-semibold flex items-center text-[12px]  ">
          {amount}
        </div>
        <div className="text-[#F7F7F7] font-semibold text-[12px] flex gap-1 items-center">
          {/* @ts-ignore:next-line */}
          <div className="w-[14px] h-[14px] sm:w-[28px] sm:h-[28px]">
            {currency?.symbol ? React.createElement(coinIcons[currency.symbol as keyof typeof coinIcons]) : null}
          </div>

          {currency?.symbol}
        </div>

        <button
          className="flex items-center gap-2 font-semibold text-[12px] text-yellow-text"
          onClick={handleJoinGame}
        >
          {isError ? (
            errorMessage
          ) : (
            <>
              PLAY
              <OrangeArrowIcon />
            </>
          )}
        </button>
      </div>

      {/* TODO Should be refactored into functions */}
      {approve && (
        <Approve
          currency={currency}
          finishApproveHandler={handleContinueJoinGame}
          debouncedBetInTokenAmount={amount * 10 ** currency!.decimals}
        />
      )}
    </div>
  );
};
