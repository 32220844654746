import { useCallback, useEffect, useRef } from 'react';
import { useReadRspContractRooms } from 'shared/features/contract.abi';
import { useRoomStore } from 'shared/store/roomStore';
import { useCurrenciesStore } from 'shared/store/currenciesStore';
import { useAccount, useBlockNumber } from 'wagmi';
import { CloserEnum, RoomStatusEnum } from 'shared/constants/shared-enums';
import { useNavigate, useParams } from 'react-router-dom';
import useRevealLogic from 'modules/Room/Hooks/useRevealLogic';
import { useRoundInfo } from 'modules/Room/Hooks/useRoundInfo';
import { NULL_ADDRESS } from 'shared/constants/constants';
import { ROUTES } from 'shared/constants';
import { useGlobalStore } from 'shared/store/globalStore';
import * as Sentry from '@sentry/react';
import { epochToLocalUTC } from 'shared/utils/time';
import { CHAINS_IDS } from 'shared/config';
import { useGameStatusStore } from '../game-status/store/game-status-store';
import { GameStatusEnum } from '../game-status/constants/game-status-enum';

export const useActiveRoom = () => {
  const { activeRoomId } = useParams();
  useRoundInfo();
  useRevealLogic();
  const { addActiveGame } = useGlobalStore();

  const {
    setRoom,
    setRefetchRoom,
    setRoomsDataIsFetching,
    roomStatus,
    refetchRoom,
    joinRoomId,
    setJoinRoomId,
    playerA,
    playerB,
    someoneElseGame,
  } = useRoomStore();
  const { address, chainId } = useAccount();
  const { data: lastBlock, dataUpdatedAt: lastBlockUpdatedAt } = useBlockNumber(
    {
      cacheTime: 0,
    }
  );
  useEffect(() => {
    if (lastBlock) {
      Sentry.setTag('blockNumber', lastBlock);
      Sentry.setTag('blockUpdatedAt', lastBlockUpdatedAt);
    }
  }, [lastBlock, lastBlockUpdatedAt]);
  const { data, dataUpdatedAt, refetch, error, isFetching } =
    useReadRspContractRooms({
      args: [BigInt(activeRoomId ?? 0)],
      query: {
        enabled: !!activeRoomId,
      },
    });

  useEffect(() => {
    if (activeRoomId) {
      if (
        (playerA.address === address?.toLowerCase() ||
          playerB.address === address?.toLowerCase()) &&
        roomStatus !== RoomStatusEnum.Closed &&
        !isFetching &&
        CHAINS_IDS.includes(chainId as 1 | 42161 | 137 | 8453)
      ) {
        addActiveGame(chainId! + address.toLowerCase(), activeRoomId!);
      }
    }
  }, [activeRoomId, playerA, playerB, isFetching, roomStatus, chainId]);
  const { setSelectedCurrency } = useCurrenciesStore();

  const { resetGameStatus, setGameStatus } = useGameStatusStore();
  const availableCurrencies = useCurrenciesStore(
    (state) => state.availableCurrencies
  );
  const navigate = useNavigate();
  const { removeActiveGame } = useGlobalStore();
  const setRoomValues = useCallback(
    (dataFromContract: any) => {
      const [
        data,
        pot,
        roundCounter,
        winsCounter,
        closer,
        deadline,
        status,
        winnerStatus,
      ] = dataFromContract;
      if (
        data?.playerA.toLowerCase() === NULL_ADDRESS &&
        data?.playerB.toLowerCase() === NULL_ADDRESS
      ) {
        removeActiveGame(chainId! + address!.toLowerCase());
        navigate(ROUTES.LOBBY);
        return;
      }

      const playerARoom = {
        address: data?.playerA.toLowerCase(),
        wins: winsCounter?.winsPlayerA,
      };

      const amIPlayerA = playerARoom.address === address?.toLowerCase();

      const iAmConnectedPlayerB =
        data?.playerB.toLowerCase() === NULL_ADDRESS &&
        !amIPlayerA &&
        joinRoomId;
      if (joinRoomId) setJoinRoomId(null);
      const playerBRoom = {
        address: iAmConnectedPlayerB
          ? address?.toLowerCase()
          : data?.playerB.toLowerCase(),
        wins: winsCounter?.winsPlayerB,
      };
      const someoneElseGame =
        !amIPlayerA &&
        playerBRoom.address !== address?.toLowerCase() &&
        (data?.playerB.toLowerCase() !== NULL_ADDRESS || !!winnerStatus);
      if (iAmConnectedPlayerB) {
        resetGameStatus();
        setGameStatus(GameStatusEnum.CONNECTING_TO_PLAYER, true);
      }

      const currencyObject = availableCurrencies.find(
        (c) => c.address.toLowerCase() === data.token.toLowerCase()
      );

      if (currencyObject) {
        setSelectedCurrency(currencyObject.symbol);
        Sentry.setTag('roomId', activeRoomId);
        Sentry.setTag('roomStatus', status);
        Sentry.setTag('wallet', address);
        Sentry.setTag('roundCounter', roundCounter);
        Sentry.setTag('winnerStatus', winnerStatus);
        Sentry.setTag('closer', closer);
        Sentry.setTag('deadline', deadline);
        Sentry.setTag(
          'dataUpdatedAt',
          JSON.stringify(epochToLocalUTC(BigInt(dataUpdatedAt)))
        );
        setRoom({
          winsTo: data.wins,
          bet: Number(Number(data.bet) / Number(10 ** currencyObject.decimals)),
          roomBalance: Number(pot) / 10 ** currencyObject.decimals,
          roomStatus: status,
          playerA: {
            ...playerARoom,
          },
          playerB: {
            ...playerBRoom,
          },
          roundCounter,
          winnerStatus,
          closerRoom: { closer: closer as CloserEnum, deadline },
          amIPlayerA,
          someoneElseGame,
        });
      }
    },
    [availableCurrencies, address]
  );

  useEffect(() => {
    if (data) {
      setRoomValues(data);
    }
  }, [data]);

  useEffect(() => {
    if (refetch) {
      setRefetchRoom(refetch);
    }
  }, [refetch]);

  useEffect(() => {
    setRoomsDataIsFetching(isFetching);
  }, [isFetching]);

  useEffect(() => {
    if (error) {
      console.log('active room error');
      Sentry.captureException(error);
    }
  }, [error]);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    if (activeRoomId && roomStatus !== RoomStatusEnum.Closed) {
      intervalRef.current = setInterval(() => {
        refetchRoom();
      }, 3000);
    } else {
      clearInterval(intervalRef.current!);
    }
    return () => {
      clearInterval(intervalRef.current!);
    };
  }, [activeRoomId, roomStatus]);
};
