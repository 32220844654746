import React from 'react';
import PrimaryButton from 'shared/components/PrimaryButton/PrimaryButton';
import { useConnectModal } from '@rainbow-me/rainbowkit';
import wallet from 'assets/png/wallet.png';
import { useTranslation } from 'react-i18next';

interface ConnectWalletProps {
  title: string;
  description: string;
}

export const ConnectWallet = ({ title, description }: ConnectWalletProps) => {
  const { openConnectModal } = useConnectModal();
  const { t } = useTranslation();
  return (
    <div className="w-full h-[100vh] flex justify-center items-center overflow-hidden relative">
      <div className="p-6 bg-modal-gradient flex flex-col gap-5 items-center border-[2px] border-border rounded-[24px]">
        <img src={wallet} alt="wallet" className="w-[150px] h-[150px]" />
        <p className="gradient-title-gold text-2xl capitalize font-bold leading-none">
          {t(title)}
        </p>
        <p className="text-sm text-white max-w-[280px] text-center opacity-70">
          {t(description)}
        </p>

        <div className="w-full h-[1px] bg-border" />
        <PrimaryButton
          text={'common.connect'}
          onClick={openConnectModal as () => void}
          className="w-full"
        />
      </div>
    </div>
  );
};
