import { useAccount } from 'wagmi';

import { Account } from 'shared/testing-add-only-wagmi-for-wallet-connection/Account';
import { Connect } from 'shared/testing-add-only-wagmi-for-wallet-connection/Connect';

export function ConnectWallet() {
  const { isConnected } = useAccount();
  return (
    <div className="h-full w-full flex justify-center items-center">{isConnected ? <Account /> : <Connect />}</div>
  );
}
