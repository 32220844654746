import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextWithLeftColorLine from 'shared/components/TextWithLeftColorLine/TextWithLeftColorLine';
import useMediaQuery from 'shared/hooks/useMediaQuery';
import AnimatedTransition from 'shared/components/AnimatedTransition/AnimatedTransition';
import { usePlayerGamesStore } from 'shared/store/playerGamesStore';
import { useAccount } from 'wagmi';
import MintingLoader from 'shared/components/Loaders/MintingLoader/MintingLoader';
import { technicalDefeatMessages } from 'shared/constants/text-loaders';
import { useTimerLogic } from '../../hooks/use-timer-logic';
import PrimaryButton from '@/shared/components/PrimaryButton/PrimaryButton';
import DigitalClock from '@/shared/components/DigitalClock/DigitalClock';
import { epochToLocalUTC } from '@/shared/utils/time';
import { useRoomStore } from '@/shared/store/roomStore';

export const WaitOpponentMoveStatus = () => {
  const [showTimer, setShowTimer] = useState(false);
  const [showCloseButton, setShowCloseButton] = useState(false);
  const [technicalDefeatError, setTechnicalDefeatError] = useState(false);
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width: 1200px)');
  const { chainId } = useAccount();
  const { closeHash } = usePlayerGamesStore();
  const { closerRoom } = useRoomStore();

  const { 
    isSuccess,
    showInnerTimer,
    closeUnusedRoomHandler,
    onExpire
  } = useTimerLogic({
    selector: isMobile ? '.player-card-host' : '.player-card-opponent',
    gameStatusName: "waitOpponentMove",
    setShowTimer,
    setShowCloseButton,
    setTechnicalDefeatError
  });

  return (
    <div className="sm:w-full p-[20px] sm:p-[30px] border border-border rounded-[12px] sm:rounded-[0] sm:border-0 bg-grape sm:bg-[transparent]">
      <AnimatedTransition show={!!closeHash[chainId!]?.hash}>
        <MintingLoader
          className="absolute top-2/3 sm:top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
          hash={closeHash[chainId!]?.hash}
          selector=".player-card-opponent"
          messages={technicalDefeatMessages}
        />
      </AnimatedTransition>
      <AnimatedTransition show={!closeHash[chainId!]?.hash}>
        <AnimatedTransition
          show={!showTimer && !showCloseButton && !technicalDefeatError}
        >
          <p className="text-[14px] sm:text-[24px] mb-[10px] text-green font-bold">
            {t('modals.waitOpponentMove.youMadeMove')}
          </p>
          <p className="text-white text-[10px] sm:text-[16px]  text-justify">
            {t('modals.waitOpponentMove.waiting')}
          </p>
        </AnimatedTransition>
        <AnimatedTransition show={showTimer}>
          <TextWithLeftColorLine colorClass="bg-green">
            <p className="text-[14px] sm:text-[24px] mb-[10px] text-green font-bold">
              {t('modals.waitOpponentMove.youMadeMove')}
            </p>
            <p className="text-white text-[10px] sm:text-[16px] mb-[10px] text-justify">
              {t('modals.waitOpponentMove.waitingTimer')}
            </p>
            <p className="text-white text-[10px] sm:text-[16px]  text-justify">
              {t('modals.waitOpponentMove.patient')}
            </p>
          </TextWithLeftColorLine>
        </AnimatedTransition>
        <AnimatedTransition show={showCloseButton}>
          <TextWithLeftColorLine colorClass="bg-red">
            <p className="text-[14px] sm:text-[24px] mb-[10px] text-red font-bold">
              {t('modals.waitOpponentMove.gameOver')}
            </p>
            <p className="text-white text-[12px] sm:text-[16px]  text-justify">
              {t('modals.waitOpponentMove.technicalDefeatExplanation')}
            </p>
          </TextWithLeftColorLine>
        </AnimatedTransition>
        <AnimatedTransition show={technicalDefeatError}>
          <TextWithLeftColorLine colorClass="bg-red" className="mb-[40px]">
            <p className="text-[14px] sm:text-[24px] mb-[10px] text-red font-bold">
              {t('modals.waitOpponentMove.technicalDefeatError')}
            </p>
            <p className="text-white text-[12px] sm:text-[16px]  text-justify">
              {t('modals.waitOpponentMove.technicalDefeatErrorExplanation')}
            </p>
          </TextWithLeftColorLine>
        </AnimatedTransition>

        <AnimatedTransition show={showCloseButton && !isSuccess}>
          <div className="flex justify-center mt-[20px]">
            <PrimaryButton
              text={'common.technicalDefeat'}
              onClick={closeUnusedRoomHandler}
            />
          </div>
        </AnimatedTransition>
        <AnimatedTransition show={showTimer && !showCloseButton}>
          <div className='w-full flex justify-center mt-[24px]'>
            <DigitalClock
              key={(epochToLocalUTC(closerRoom?.deadline) as Date).toString()}
              expiryTimestamp={epochToLocalUTC(closerRoom?.deadline)}
              className='w-fit'
              onExpire={onExpire}
            />
          </div>
        </AnimatedTransition>
      </AnimatedTransition>
    </div>
  );
};
