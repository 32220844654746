import React, { useEffect, useRef } from 'react';
import { useRoomStore } from 'shared/store/roomStore';
import { Timer } from 'shared/constants/types';
import { useTimersStore } from 'shared/store/timersStore';
import { NO_PLAYER_FOUND } from 'shared/constants/timers';
import { RoomStatusEnum } from 'shared/constants/shared-enums';
import AnimatedTransition from 'shared/components/AnimatedTransition/AnimatedTransition';
import { useParams } from 'react-router-dom';
import { useAccount } from 'wagmi';
import { NULL_ADDRESS } from 'shared/constants/constants';
import { useCurrenciesStore } from 'shared/store/currenciesStore';
import { useBalance } from 'shared/hooks/useBalance';
import { useGlobalStore } from 'shared/store/globalStore';
import { usePlayerGamesStore } from 'shared/store/playerGamesStore';
import useMediaQuery from 'shared/hooks/useMediaQuery';
import { ROUTES } from 'shared/constants';
import { useNavigate } from 'react-router-dom';
import { GameStatusEnum } from '../../constants/game-status-enum';
import { 
  ConnectingToPlayerStatus,
  CreateNewGameStatus, 
  LookingOpponentStatus,
  NoOneJoinStatus,
  NotEnoughBalanceStatus,
  ConnectingToOpponentLoaderStatus,
} from './statuses';
import { TransactionTakeToLongModal } from '../modals';
import { useGameStatusStore } from '../../store/game-status-store';
import { CardPlayerEnum } from '@/modules/Room/constants/enums';

interface InitGameStatusesProps {
  player?: CardPlayerEnum;
}

export const InitGameStatuses = ({ player }: InitGameStatusesProps) => {
  const timerRef = useRef<Timer | null>(null);
  const navigate = useNavigate();
  const { roomBalance, roomStatus, roomsDataIsFetching, playerB, playerA } =
    useRoomStore();
  const { joinHash, startHash } = usePlayerGamesStore();
  const { activeRoomId } = useParams();
  const { address, chainId } = useAccount();
  const {
    resetGameStatus,
    gameStatus,
    setNotEnoughModalContinue,
    setNotEnoughModalZeroBalance,
    setGameStatus,
  } = useGameStatusStore();
  const { loaderStartTime, setLoaderStartTime } = useTimersStore();
  const { selectedCurrency } = useCurrenciesStore();
  const { balance } = useBalance({
    currencyAddress: selectedCurrency?.address ?? undefined,
    walletAddress: address as `0x${string}`,
  });
  const { setNotFirstTime, notFirstTime } = useGlobalStore();
  const isMobile = useMediaQuery('(max-width: 1200px)');
  useEffect(() => {
    if (
      selectedCurrency &&
      typeof balance === 'number' &&
      +selectedCurrency.bet?.[0] > balance! &&
      !notFirstTime &&
      !activeRoomId
    ) {
      resetGameStatus();
      setGameStatus(GameStatusEnum.NOT_ENOUGH_COINS, true);
      setNotEnoughModalContinue(true);
      setNotEnoughModalZeroBalance(balance === 0);
      setNotFirstTime(true);
    }
  }, [selectedCurrency, balance]);
  useEffect(() => {
    if (
      roomBalance === 0n &&
      !activeRoomId &&
      !gameStatus[GameStatusEnum.CREATE_NEW_ROOM] &&
      !gameStatus[GameStatusEnum.CONNECT_TO_PLAYER] &&
      !gameStatus[GameStatusEnum.NOT_ENOUGH_COINS] &&
      !startHash[chainId!]?.hash &&
      !joinHash[chainId!]?.hash
    ) {
      resetGameStatus();
      navigate(ROUTES.LOBBY);
    } else if (startHash[chainId!]?.hash) {
      resetGameStatus();
      setGameStatus(GameStatusEnum.CREATE_NEW_ROOM, true);
    } else if (joinHash[chainId!]?.hash) {
      resetGameStatus();
      setGameStatus(GameStatusEnum.CONNECTING_TO_PLAYER, true);
    }
  }, [roomBalance, activeRoomId, startHash, joinHash]);

  useEffect(() => {
    if (
      activeRoomId &&
      ![
        RoomStatusEnum.ReadyForGame,
        RoomStatusEnum.ReadyForReveal,
        RoomStatusEnum.Closed,
      ].includes(roomStatus) &&
      !roomsDataIsFetching &&
      playerB?.address === NULL_ADDRESS &&
      playerA?.address === address?.toLowerCase()
    ) {
      if (!loaderStartTime?.[activeRoomId + address + chainId]) {
        setLoaderStartTime(activeRoomId + address + chainId, new Date());
        resetGameStatus();
        setGameStatus(GameStatusEnum.LOOKING_OPPONENT, true);
        timerRef.current = setTimeout(() => {
          setGameStatus(GameStatusEnum.NO_ONE_JOIN, true);
          if (isMobile) setGameStatus(GameStatusEnum.LOOKING_OPPONENT, false);
        }, NO_PLAYER_FOUND);
      } else {
        const currentTime = new Date();
        const value = loaderStartTime?.[activeRoomId! + address + chainId];
        const date = value ? new Date(value) : null;
        let diff;
        if (date) diff = currentTime.getTime() - date.getTime();
        if (diff && diff > NO_PLAYER_FOUND) {
          resetGameStatus();
          setGameStatus(GameStatusEnum.LOOKING_OPPONENT, !isMobile);
          setGameStatus(GameStatusEnum.NO_ONE_JOIN, true);
        } else if (diff) {
          resetGameStatus();
          setGameStatus(GameStatusEnum.LOOKING_OPPONENT, true);
          timerRef.current = setTimeout(() => {
            setGameStatus(GameStatusEnum.NO_ONE_JOIN, true);
            if (isMobile) setGameStatus(GameStatusEnum.LOOKING_OPPONENT, false);
          }, NO_PLAYER_FOUND - diff);
        }
      }
    }

    if (
      [
        RoomStatusEnum.ReadyForGame,
        RoomStatusEnum.ReadyForReveal,
        RoomStatusEnum.Closed,
      ].includes(roomStatus) &&
      loaderStartTime?.[activeRoomId! + address + chainId]
    ) {
      setLoaderStartTime(activeRoomId! + address + chainId, null);
    }
    return () => clearTimeout(timerRef.current as Timer);
  }, [
    selectedCurrency,
    balance,
    activeRoomId,
    roomStatus,
    roomsDataIsFetching,
    isMobile,
  ]);

  if (gameStatus[GameStatusEnum.NOT_ENOUGH_COINS] && player === CardPlayerEnum.Player) {
    return <NotEnoughBalanceStatus />
  }

  if (gameStatus[GameStatusEnum.CREATE_NEW_ROOM] && player === CardPlayerEnum.Player) {
    return <CreateNewGameStatus />
  }

  if (gameStatus[GameStatusEnum.CONNECT_TO_PLAYER] && player === CardPlayerEnum.Player) {
    return <ConnectingToPlayerStatus />
  }

  if (gameStatus[GameStatusEnum.LOOKING_OPPONENT] && player === CardPlayerEnum.Opponent) {
    return <LookingOpponentStatus />
  }

  if (gameStatus[GameStatusEnum.NO_ONE_JOIN] && player === CardPlayerEnum.Player) {
    return <NoOneJoinStatus />
  }

  if (gameStatus[GameStatusEnum.CONNECTING_TO_PLAYER] && player === CardPlayerEnum.Player) {
    return <ConnectingToOpponentLoaderStatus />
  }

  if (gameStatus[GameStatusEnum.TRANSACTION_TAKE_TO_LONG] && player === CardPlayerEnum.Player) {
    return (
      <AnimatedTransition
        show={gameStatus[GameStatusEnum.TRANSACTION_TAKE_TO_LONG]}
      >
        <TransactionTakeToLongModal
          open={gameStatus[GameStatusEnum.TRANSACTION_TAKE_TO_LONG]}
        />
      </AnimatedTransition>
    )
  }

  return null;
};
