import { useAccount } from 'wagmi';
import MobileRoom from 'modules/Room/pages/MobileRoom';
import DesktopRoom from 'modules/Room/pages/DesktopRoom';
import useMediaQuery from 'shared/hooks/useMediaQuery';
import { useParams } from 'react-router-dom';
import { SignModal } from 'modules/ConnectPage/components/SignModal/SignModal';
import AnimatedTransition from 'shared/components/AnimatedTransition/AnimatedTransition';
import { useShowSignModal } from 'shared/hooks/useShowSignModal';
import { ConnectWallet } from 'shared/components/connect-wallet/connect-wallet';
import { ChangeNetwork } from 'shared/components/change-network/change-network';

export const Room = () => {
  const { isConnected, chainId } = useAccount();
  const { showSignModal } = useShowSignModal();
  const isMobile = useMediaQuery('(max-width: 1200px)');
  const { networkId } = useParams();

  if (!isConnected) {
    return <ConnectWallet
      title={'room.connect'}
      description={'room.connectDesc'}
    />;
  }

  if (Number(networkId) !== chainId) {
    return <ChangeNetwork
      title={'room.switch'}
      description={'room.switchDesc'}
    />;
  }
  return (
    <>
      <AnimatedTransition show={showSignModal}>
        <SignModal />
      </AnimatedTransition>
      {isMobile ? <MobileRoom /> : <DesktopRoom />}
    </>
  );
};
