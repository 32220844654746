import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useConnectModal } from '@rainbow-me/rainbowkit';
import { useAccount, useDisconnect } from 'wagmi';
import logo from 'assets/png/logo.png';
import WalletIcon from 'assets/wallet.svg?react';
import LogoutIcon from 'assets/logout.svg?react';
import PrimaryButton from 'shared/components/PrimaryButton/PrimaryButton';
import { Avatar } from 'shared/components/Avatar/Avatar';
import useMediaQuery from 'shared/hooks/useMediaQuery';
import { useRoomStore } from 'shared/store/roomStore';
import useLocalStorageCheck from 'shared/hooks/useLocalStorageCheck';
import NotWorkingLocalStorageModal from 'shared/components/NotWorkingLocalStorageModal/NotWorkingLocalStorageModal';
import AnimatedTransition from 'shared/components/AnimatedTransition/AnimatedTransition';
import MobileDropDown from 'modules/Room/mobile/MobileDropDown/MobileDropDown';
import PlaySequence from 'modules/ConnectPage/components/PlaySequence/PlaySequence';
import { ChainsSwitcher } from 'modules/ConnectPage/components';
import { useGlobalStore } from 'shared/store/globalStore';

import 'modules/ConnectPage/page/rainbowkit-styles.scss';
import './ConnectPage.scss';
import ConnectPage from 'modules/ConnectPage/page/ConnectPage';
import { ConnectWallet } from 'shared/testing-add-only-wagmi-for-wallet-connection/ConnectWallet';

const DesktopConnectPage = () => {
  const isLocalStorageAvailable = useLocalStorageCheck();
  const [menuOpen, setMenuOpen] = React.useState(false);
  const { isConnected, address } = useAccount();
  const { t } = useTranslation();
  const { openConnectModal } = useConnectModal();
  const { disconnectAsync } = useDisconnect();
  const { setPlayerAAddress } = useRoomStore();
  const { jwt } = useGlobalStore();

  const isXLDesktop = useMediaQuery('(min-width: 1400px)');
  useEffect(() => {
    isXLDesktop && !isConnected && openConnectModal && openConnectModal();
  }, [isXLDesktop]);

  useEffect(() => {
    if (isConnected) {
      setPlayerAAddress(address?.toLowerCase() as `0x${string}`);
    }
  }, [isConnected]);

  const handleOutsideClick = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setMenuOpen(false);
    }
  };
  const menuRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (menuOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [menuOpen]);

  const disconnectHandler = () => {
    disconnectAsync();
    setTimeout(() => {
      disconnectAsync();
    }, 100);
    setMenuOpen(false);
  };

  return (
    <div className="flex items-center flex-col h-screen overflow-auto">
      <div className="pt-[70px] px-[50px] xlg:px-[132px] pb-0 bg-transparent max-w-[1940px] w-full">
        <header className="flex justify-between items-center mb-0">
          <img
            className="h-[108px]"
            src={logo}
            alt={'rock paper scissors logo'}
          />
          <div className="flex items-center">
            <a
              className={`text-white text-[24px] font-semibold	hover:text-yellow-text mr-[40px]`}
              href="https://rock-paper-scissors.game/rules"
              target="_blank"
            >
              {t('pages.about')}
            </a>
            <a
              className={`text-white text-[24px] font-semibold	hover:text-yellow-text ${
                isConnected ? 'mr-[40px]' : ''
              }`}
              href="https://rock-paper-scissors.game/referral"
              target="_blank"
            >
              {t('pages.referral')}
            </a>
            <AnimatedTransition show={isConnected}>
              <button onClick={() => setMenuOpen(!menuOpen)}>
                <Avatar
                  userAddress={address?.toLowerCase() as `0x${string}`}
                  className="w-[50px] h-[50px] rounded-[50%]"
                />
              </button>
            </AnimatedTransition>
          </div>
          <MobileDropDown
            open={menuOpen}
            ref={menuRef}
            list={[
              {
                label: 'Logout',
                value: 'logout',
                svg: <LogoutIcon className="h-[25px] w-[25px] ml-[10px] " />,
              },
            ]}
            onClick={disconnectHandler}
            position={{ top: 155, right: isXLDesktop ? 125 : 50 }}
          />
        </header>
      </div>
      <div
        className={`flex flex-col xlg:flex-row  xlg:px-[132px]  pt-[70px] ${
          isConnected ? 'xlg:pt-[70px] sm:p-0 align-baseline' : ' items-center '
        } max-w-[1940px] w-full`}
      >
        <div className="xlg:w-[50%] sm:items-center min-w-[575px] sm:px-[50px] xlg:px-0 xlg:mr-[30px] flex flex-col sm:justify-center xlg:justify-start xlg:items-start">
          <AnimatedTransition show={isConnected} asFragment>
            <h3 className="text-yellow-text font-black sm:text-[45px] xlg:text-[58px] sm:mb-[20px] xlg:mb-[48px] xlg:leading-[110%] uppercase">
              {t('common.select')}
              <span className="text-white"> {t('common.network')}</span>
            </h3>
          </AnimatedTransition>
          <AnimatedTransition show={!isConnected} asFragment>
            <h3 className="text-yellow-text font-black sm:text-[45px] xlg:text-[58px] sm:mb-[20px] xlg:mb-[48px] xlg:leading-[110%] uppercase">
              {t('common.connectWallet')} <br className="sm:hidden xlg:block" />
              <span className="text-white">{t('common.playTheGame')}</span>
            </h3>
          </AnimatedTransition>
          <div className="py-[24px] px-[42px] border-[3px] border-border rounded-[8px] flex flex-col items-center mb-[20px] xlg:mb-[48px] bg-[#060445] xlg:w-[100%]">
            <PlaySequence key="firstPlaySequence" isConnected={isConnected} />
          </div>
          <AnimatedTransition show={!isConnected}>
            <p className="text-[25px] sm:w-[660px] sm:text-center xlg:text-left xlg:w-full text-simple-text leading-[140%] mb-[20px]">
              {t('common.gameWeb3')}
            </p>
          </AnimatedTransition>
          <AnimatedTransition show={isConnected}>
            <p className="text-[25px] sm:hidden xlg:block sm:text-center xlg:text-left xlg:w-full text-simple-text leading-[140%] mb-[20px]">
              {t('common.chooseBlockchainNetwork')}
            </p>
          </AnimatedTransition>
        </div>
        <div className="xlg:w-[50%] relative flex items-center justify-center">
          {!isConnected && (
            <div className="flex justify-center items-center flex-col">
              {/* TODO  add gradient blue  remove in separate svg*/}
              {/*<WalletIcon className="opacity-70 hidden xlg:block" />*/}
              {/*<PrimaryButton*/}
              {/*  text={'common.connect'}*/}
              {/*  onClick={openConnectModal as () => void}*/}
              {/*/>*/}
              <ConnectWallet />
            </div>
          )}
          <AnimatedTransition
            show={!!(isConnected && address && !!jwt[address])}
            asFragment
          >
            <div
              className={`z-0 xlg:w-full bg-[#060445] xlg:border rounded-[16px] mb-8 p-[24px] xlg:border-dark-border sm:border-[3px] sm:border-border chains-container overflow-auto sm:w-[660px]`}
            >
              {/*<ChainsSwitcher />*/}
              <ConnectWallet />
            </div>
          </AnimatedTransition>
        </div>
      </div>
      <AnimatedTransition show={!isLocalStorageAvailable}>
        <NotWorkingLocalStorageModal open={!isLocalStorageAvailable} />
      </AnimatedTransition>
    </div>
  );
};

export default DesktopConnectPage;
