import TextWithLeftColorLine from 'shared/components/TextWithLeftColorLine/TextWithLeftColorLine';
import { useTranslation } from 'react-i18next';
import AnimatedTransition from 'shared/components/AnimatedTransition/AnimatedTransition';
import MintingLoader from 'shared/components/Loaders/MintingLoader/MintingLoader';
import { useAccount } from 'wagmi';
import { usePlayerGamesStore } from 'shared/store/playerGamesStore';
import { creatingMessages } from 'shared/constants/text-loaders';

export const ConnectingToPlayerStatus = () => {
  const { t } = useTranslation();
  const { chainId } = useAccount();
  const { joinHash } = usePlayerGamesStore();
  return (
    <>
      <AnimatedTransition show={!!joinHash[chainId!]?.hash}>
        <MintingLoader
          className="absolute top-2/3 sm:top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
          hash={joinHash[chainId!]?.hash}
          messages={creatingMessages}
          selector=".player-card-host"
        />
      </AnimatedTransition>
      <AnimatedTransition show={!joinHash[chainId!]?.hash}>
        <div className="sm:w-full p-[20px] sm:p-[30px] border border-border rounded-[12px] sm:rounded-[0] sm:border-0 bg-grape sm:bg-[transparent]">
          <AnimatedTransition show={!joinHash[chainId!]?.hash}>
            <p className="text-[14px] sm:text-[24px] mb-[10px] text-green font-bold">
              {t('modals.connectingGame.connecting')}
            </p>
            <p className="text-white text-justify text-[12px] sm:text-[17px]">
              {t('modals.connectingGame.approve')}
            </p>
          </AnimatedTransition>
        </div>
      </AnimatedTransition>
    </>
  );
};
