import { PlayGameStatuses } from './components/play-game-statuses';
import { InitGameStatuses } from './components/init-game-statuses';
import { CardPlayerEnum } from '../constants/enums';

interface GameStatusProps {
  player?: CardPlayerEnum;
}

export const GameStatus = ({ player }: GameStatusProps) => {
  return (
    <div className='relative sm:static z-[10000]'>
      <InitGameStatuses player={player} />
      <PlayGameStatuses player={player} />
    </div>
  );
};
