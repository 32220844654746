import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MintingLoader from 'shared/components/Loaders/MintingLoader/MintingLoader';
import { useAccount } from 'wagmi';
import { usePlayerGamesStore } from 'shared/store/playerGamesStore';
import { creatingMessages } from 'shared/constants/text-loaders';
import AnimatedTransition from 'shared/components/AnimatedTransition/AnimatedTransition';

export const CreateNewGameStatus = () => {
  const { t } = useTranslation();
  const { chainId } = useAccount();
  const { startHash } = usePlayerGamesStore();
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    if (chainId && startHash?.[chainId]?.hash) setShowLoader(true);
  }, [startHash]);
  return (
    <>
      <AnimatedTransition show={showLoader}>
        <MintingLoader
          className="absolute top-2/3 sm:top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
          hash={startHash[chainId!]?.hash}
          messages={creatingMessages}
          selector=".player-card-host"
        />
      </AnimatedTransition>
      <AnimatedTransition show={!showLoader}>
      <div className="sm:w-full p-[20px] sm:p-[30px] border border-border rounded-[12px] sm:rounded-[0] sm:border-0 bg-grape sm:bg-[transparent]">
        <p className="text-[14px] sm:text-[24px] mb-[10px] text-green font-bold">
          {t('modals.createNewGame.creating')}
        </p>
        <p className="text-white text-justify text-[10px] sm:text-[16px]">
          {t('modals.createNewGame.approve')}
        </p>
      </div>
      </AnimatedTransition>
    </>
  );
};
