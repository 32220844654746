import { useWriteRspContractPlayRoomGame } from 'shared/features/contract.abi';
import {
  MovesEnum,
  RoomStatusEnum,
  RoundStagesEnum,
} from 'shared/constants/shared-enums';
import { utils } from 'ethers';
import { useAccount } from 'wagmi';
import { useRoomStore } from 'shared/store/roomStore';
import { useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { usePlayerGamesStore } from 'shared/store/playerGamesStore';
import { WriteError } from 'shared/types/app-types';
import { useTransactionReceipt } from 'shared/hooks/useTransactionReceipt';
import { generateSubSalt } from 'shared/constants/utils';
import * as Sentry from '@sentry/react';
import { estimateGasForFunction } from 'shared/utils/estimateGas';
import { useGameStatusStore } from '../game-status/store/game-status-store';
import { GameStatusEnum } from '../game-status/constants/game-status-enum';

export const useMoveHandler = () => {
  const { address, chainId } = useAccount();
  const [moveHashLocal, setMoveHashLocal] = useState<
    `0x${string}` | undefined
  >();
  const { setGameStatus, resetGameStatus, setPlayMoveGameStatus } =
    useGameStatusStore();
  const { playerA, roundStage, roomStatus, roundCounter, refetchRoom } =
    useRoomStore();
  const { activeRoomId } = useParams();
  const { setPlayMove, setMoveHash, mainSalt } = usePlayerGamesStore();
  const selectMoveHandlerRef = useRef<null | boolean>(null);
  const { writeContract, data, isSuccess, isPending, isError, error } =
    useWriteRspContractPlayRoomGame();
  const { setTransactionCallBack } = usePlayerGamesStore();

  const amIPlayerA = playerA.address === address;
  const {
    isSuccess: isSuccessPlayMove,
    isError: isErrorPlayMove,
    error: transactionError,
    limitExceed,
  } = useTransactionReceipt(
    moveHashLocal,
    usePlayerGamesStore.getState().playMoves[
      activeRoomId! + address! + chainId + roundCounter
    ]?.moveHash?.date
  );

  useEffect(() => {
    if (limitExceed) {
      console.log('limit exceed useMoveHandler');
      setGameStatus(GameStatusEnum.TRANSACTION_TAKE_TO_LONG, true);
    }
  }, [limitExceed]);

  useEffect(() => {
    if (isSuccess) {
      const now = new Date();
      const timeString = now.toLocaleTimeString();
      setMoveHash(
        activeRoomId! + address! + chainId + roundCounter,
        data!,
        now
      );
      setMoveHashLocal(data!);
      console.log('playMove submitted', isSuccess, timeString);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (
      !moveHashLocal &&
      !selectMoveHandlerRef.current &&
      (roundStage === RoundStagesEnum.None ||
        roundStage === RoundStagesEnum.InitGame) &&
      roomStatus === RoomStatusEnum.ReadyForGame
    ) {
      if (
        usePlayerGamesStore.getState().playMoves[
          activeRoomId! + address! + chainId + roundCounter
        ]?.move &&
        !usePlayerGamesStore.getState().playMoves[
          activeRoomId! + address! + chainId + roundCounter
        ]?.moveHash?.hash
      ) {
        setPlayMove(
          (activeRoomId! + address! + chainId + roundCounter) as string,
          {
            playerAddress: address || '',
            salt: null,
            move: MovesEnum.None,
            moveHash: null,
            revealHash: null,
          }
        );
      }
      if (
        usePlayerGamesStore.getState().playMoves[
          activeRoomId! + address! + chainId + roundCounter
        ]?.moveHash?.hash
      )
        setMoveHashLocal(
          usePlayerGamesStore.getState().playMoves[
            activeRoomId! + address! + chainId + roundCounter
          ]?.moveHash!.hash
        );
    }
  }, [
    moveHashLocal,
    activeRoomId,
    address,
    chainId,
    roundCounter,
    roundStage,
    roomStatus,
  ]);

  const writeContractWithArgs = async (salt: string, move: MovesEnum) => {
    if (!activeRoomId) return;
    const args = [
      BigInt(activeRoomId),
      roundCounter,
      utils.solidityKeccak256(
        ['string', 'uint8', 'address'],
        [salt, move, address]
      ) as `0x${string}`,
    ];
    let gas = await estimateGasForFunction('playRoomGame', args);

    writeContract({
      args: [
        BigInt(activeRoomId),
        roundCounter,
        utils.solidityKeccak256(
          ['string', 'uint8', 'address'],
          [salt, move, address]
        ) as `0x${string}`,
      ],
      gas,
    });
  };

  const selectMoveHandler = async (move: MovesEnum) => {
    if (selectMoveHandlerRef.current || roomStatus === RoomStatusEnum.Closed)
      return;
    selectMoveHandlerRef.current = true;
    resetGameStatus();
    setGameStatus(GameStatusEnum.WAIT_YOUR_APPROVE, true);
    const salt = await generateSubSalt(
      mainSalt[activeRoomId! + address! + chainId] as string,
      roundCounter
    );
    setPlayMove((activeRoomId! + address! + chainId + roundCounter) as string, {
      playerAddress: address || '',
      salt,
      move,
      moveHash: null,
      revealHash: null,
    });
    try {
      setTransactionCallBack(() => {
        writeContractWithArgs(salt, move);
      });
      await writeContractWithArgs(salt, move);
    } catch (error) {
      console.log(error);
      errorHandler();
    }
  };
  useEffect(() => {
    if (isSuccessPlayMove && roomStatus !== RoomStatusEnum.Closed) {
      const now = new Date();
      const timeString = now.toLocaleTimeString();
      console.log('success play move by receipt', timeString);
      selectMoveHandlerRef.current = null;
      if (activeRoomId! && address) {
        refetchRoom();
      }
      setPlayMoveGameStatus({ error: false, reject: false });
    }
  }, [isSuccessPlayMove, amIPlayerA, address, activeRoomId]);
  const errorHandler = () => {
    resetGameStatus();
    setGameStatus(GameStatusEnum.WAIT_YOUR_MOVE, true);
    selectMoveHandlerRef.current = null;
    setPlayMove((activeRoomId! + address! + chainId + roundCounter) as string, {
      playerAddress: address || '',
      move: MovesEnum.None,
      salt: '',
      moveHash: null,
      revealHash: null,
    });
  };
  useEffect(() => {
    if (isError || error || isErrorPlayMove || transactionError) {
      console.log('write contract error', error);
      console.log('transaction receipt', transactionError);
      if (error) {
        const typedError = error as WriteError | undefined;
        if (typedError?.cause?.code) {
          if (typedError.cause.code !== 4001) {
            Sentry.captureException(error);
          }
          setPlayMoveGameStatus({
            reject: typedError.cause.code === 4001,
            error: typedError.cause.code !== 4001,
          });
        } else {
          setPlayMoveGameStatus({
            reject: false,
            error: true,
          });
        }
      }
      if (transactionError) Sentry.captureException(transactionError);
      errorHandler();
    }
  }, [isError, error, isErrorPlayMove, transactionError]);
  return selectMoveHandler;
};
