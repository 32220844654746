import { GameStatusEnum } from '../constants/game-status-enum';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

interface State {
  gameStatus: Record<GameStatusEnum, boolean>;
  gameBetGameStatus: {
    reject: boolean;
    error: boolean;
  };
  revealGameStatus: {
    error: boolean;
    reject: boolean;
  };
  playMoveGameStatus: {
    reject: boolean;
    error: boolean;
  };
  notEnoughGameStatus: {
    continue: boolean;
    zeroBalance: boolean;
  };
  setRevealGameStatusError: ({
    reject,
    error,
  }: {
    reject: boolean;
    error: boolean;
  }) => void;
  setPlayMoveGameStatus: ({
    reject,
    error,
  }: {
    reject: boolean;
    error: boolean;
  }) => void;
  setGameStatus: (GameStatus: GameStatusEnum, flag: boolean) => void;
  resetGameStatus: () => void;
  resetStore: () => void;
  setNotEnoughModalContinue: (flag: boolean) => void;
  setNotEnoughModalZeroBalance: (flag: boolean) => void;
  setGameBetGameStatus: ({
    reject,
    error,
  }: {
    reject: boolean;
    error: boolean;
  }) => void;
}

const initialState: State = {
  gameStatus: {
    [GameStatusEnum.SET_BET]: false,
    [GameStatusEnum.NOT_ENOUGH_COINS]: false,
    [GameStatusEnum.LOOKING_OPPONENT]: false,
    [GameStatusEnum.NO_ONE_JOIN]: false,
    [GameStatusEnum.CREATE_NEW_ROOM]: false,
    [GameStatusEnum.CONNECT_TO_PLAYER]: false,
    [GameStatusEnum.TAKE_MONEY_BACK]: false,
    [GameStatusEnum.WAIT_YOUR_REVEAL]: false,
    [GameStatusEnum.WAIT_OPPONENT_MOVE]: false,
    [GameStatusEnum.WAIT_OPPONENT_REVEAL]: false,
    [GameStatusEnum.WAIT_YOUR_APPROVE]: false,
    [GameStatusEnum.WAIT_YOUR_MOVE]: false,
    [GameStatusEnum.CONNECTING_TO_PLAYER]: false,
    [GameStatusEnum.WATCH_GAME]: false,
    [GameStatusEnum.JOIN_GAME]: false,
    [GameStatusEnum.TRANSACTION_TAKE_TO_LONG]: false,
  },
  gameBetGameStatus: {
    reject: false,
    error: false,
  },
  playMoveGameStatus: {
    reject: false,
    error: false,
  },
  revealGameStatus: {
    error: false,
    reject: false,
  },
  notEnoughGameStatus: {
    continue: false,
    zeroBalance: true,
  },
  setRevealGameStatusError: () => {},
  setPlayMoveGameStatus: () => {},
  setGameStatus: () => {},
  resetGameStatus: () => {},
  resetStore: () => {},
  setNotEnoughModalContinue: () => {},
  setNotEnoughModalZeroBalance: () => {},
  setGameBetGameStatus: () => {},
};

export const useGameStatusStore = create<State>()(
  immer(
    devtools((set) => ({
      ...initialState,
      setGameStatus: (GameStatus, flag) =>
        set((state) => ({
          gameStatus: {
            ...state.gameStatus,
            [GameStatus]: flag,
          },
        })),
      setGameBetGameStatus: ({ reject, error }) =>
        set(() => ({
          gameBetGameStatus: {
            reject,
            error,
          },
        })),
        resetGameStatus: () =>
        set((state) => {
          for (const prop in state.gameStatus) {
            state.gameStatus[prop as unknown as GameStatusEnum] = false;
          }
          return state;
        }),
      setRevealGameStatusError: ({ error, reject }) =>
        set((state) => ({
          revealGameStatus: {
            ...state.revealGameStatus,
            error,
            reject,
          },
        })),
      setPlayMoveGameStatus: ({ error, reject }) =>
        set(() => ({
          playMoveGameStatus: {
            error,
            reject,
          },
        })),
      setNotEnoughModalContinue: (continueFlag) =>
        set((state) => ({
          notEnoughGameStatus: {
            ...state.notEnoughGameStatus,
            continue: continueFlag,
          },
        })),
      setNotEnoughModalZeroBalance: (zeroBalanceFlag) =>
        set((state) => ({
          notEnoughGameStatus: {
            ...state.notEnoughGameStatus,
            zeroBalance: zeroBalanceFlag,
          },
        })),
      resetStore: () => set(initialState),
    }))
  )
);
