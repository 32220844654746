import React from 'react';
import GuysLoader from 'shared/components/Loaders/GuysLoader/GuysLoader';
import { lookingForOpponent } from 'shared/constants/text-loaders';

export const LookingOpponentStatus = () => {
  return (
    <GuysLoader
      className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
      messages={lookingForOpponent}
    />
  );
};
