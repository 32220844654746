import React, { useEffect } from 'react';
import TextWithLeftColorLine from 'shared/components/TextWithLeftColorLine/TextWithLeftColorLine';
import { useTranslation } from 'react-i18next';
import Kraken from 'assets/png/kraken.png';
import Binance from 'assets/png/binance.png';
import CoinBaseLogo from 'assets/CoinbaseLogo.svg?react';
import useMediaQuery from 'shared/hooks/useMediaQuery';
import PrimaryButton from 'shared/components/PrimaryButton/PrimaryButton';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'shared/constants';
import { useGameStatusStore } from '@/modules/Room/game-status/store/game-status-store';
import { GameStatusEnum } from '@/modules/Room/game-status/constants/game-status-enum';

export const NotEnoughBalanceStatus = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const linkHandler = () => {
    setGameStatus(GameStatusEnum.NOT_ENOUGH_COINS, false);
    navigate(ROUTES.LOBBY);
  };

  const {
    resetGameStatus,
    setGameStatus,
    setNotEnoughModalContinue,
    notEnoughGameStatus,
  } = useGameStatusStore();
  useEffect(() => {
    return () => {
      setNotEnoughModalContinue(false);
    };
  }, [setNotEnoughModalContinue]);
  const continueHandler = () => {
    resetGameStatus();
    navigate(ROUTES.LOBBY);
  };

  return (
    <div className="sm:w-full p-[20px] sm:p-[30px] border border-border rounded-[12px] sm:rounded-[0] sm:border-0 bg-grape sm:bg-[transparent]">
      {notEnoughGameStatus.zeroBalance && (
        <>
          <p className="text-white text-[14px] sm:text-[24px] mb-[10px] text-red font-bold">
            {t('modals.topUpModal.empty')}
          </p>
          <p className="text-white text-[10px] sm:text-[16px] mb-[10px]">
            {t('modals.topUpModal.oops')}
          </p>
          <p className="text-white text-[10px] sm:text-[16px] mb-[24px]">
            {t('modals.topUpModal.links')}
          </p>
        </>
      )}
      {!notEnoughGameStatus.zeroBalance && (
        <>
          <p className="text-white text-[14px] sm:text-[24px] mb-[10px]">
            {t('modals.topUpModal.insufficient')}
          </p>
          <p className="text-white text-[10px] sm:text-[16px]">
            {t('modals.topUpModal.topUp')}
          </p>
        </>
      )}      
      <div className="flex items-center justify-between pb-[40px] border-border border-t-2 border-b-2 mb-[29px] pt-[40px]">
        <a
          onClick={linkHandler}
          href="https://www.kraken.com/"
          target="_blank"
          className="flex justify-center w-[33%]"
        >
          <img src={Kraken} alt="Kraken" className="h-[15px] sm:h-[23px]" />
        </a>
        <a
          href="https://www.binance.com/en"
          onClick={linkHandler}
          target="_blank"
          className="flex justify-center w-[33%]"
        >
          <img src={Binance} alt="Binance" className="h-[20px] sm:h-[30px]" />
        </a>
        <a
          onClick={linkHandler}
          href="https://www.coinbase.com/"
          target="_blank"
          className="flex justify-center w-[33%]"
        >
          <CoinBaseLogo className="h-[15px] sm:h-[25px]" />
        </a>
      </div>
      <div className="flex items-center flex-col">
        {notEnoughGameStatus.zeroBalance && (
          <p className="text-white text-[12px] sm:text-[17px]">
            {t('modals.topUpModal.tip')}
          </p>
        )}

        {notEnoughGameStatus.continue && (
          <PrimaryButton
            className={`self-center mt-[20px] ${
              !notEnoughGameStatus.zeroBalance ? 'mt-[20px]' : ''
            }`}
            text={'modals.topUpModal.continue'}
            onClick={continueHandler}
          />
        )}
      </div>
    </div>
  );
};
