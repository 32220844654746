export enum GameStatusEnum {
  SET_BET,
  NOT_ENOUGH_COINS,
  LOOKING_OPPONENT,
  CONNECTING_TO_PLAYER,
  NO_ONE_JOIN,
  TAKE_MONEY_BACK,
  CREATE_NEW_ROOM,
  CONNECT_TO_PLAYER,
  WAIT_YOUR_MOVE,
  WAIT_YOUR_APPROVE,
  WAIT_YOUR_REVEAL,
  WAIT_OPPONENT_MOVE,
  WAIT_OPPONENT_REVEAL,
  WATCH_GAME,
  JOIN_GAME,
  TRANSACTION_TAKE_TO_LONG,
}
