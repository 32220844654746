import { useTranslation } from 'react-i18next';
import PrimaryButton from 'shared/components/PrimaryButton/PrimaryButton';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'shared/constants';
import { useAccount } from 'wagmi';
import { useRoomStore } from 'shared/store/roomStore';
import { RoomStatusEnum } from 'shared/constants/shared-enums';
import { useGameStatusStore } from '@/modules/Room/game-status/store/game-status-store';

export const WatchGameStatus = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { resetGameStatus } = useGameStatusStore();
  const { address } = useAccount();
  const { resetRoom, roomStatus } = useRoomStore();
  const goToSetBet = () => {
    resetGameStatus();
    resetRoom(address!.toLowerCase() as `0x${string}`);
    navigate(ROUTES.LOBBY);
  };
  return (
    <div className="sm:w-full p-[20px] sm:p-[30px] border border-border rounded-[12px] sm:rounded-[0] sm:border-0 bg-grape sm:bg-[transparent]">
      {roomStatus !== RoomStatusEnum.Closed ? (
        <>
          <p className="text-[14px] sm:text-[24px] mb-[10px] text-yellow font-bold">
            {t('modals.watchGame.viewing')}
          </p>
          <p className="text-white text-[10px] sm:text-[16px] text-justify mb-[24px]">
            {t('modals.watchGame.start')}
          </p>
        </>
      ) : (
        <p className="text-white text-[10px] sm:text-[16px] text-justify">
          {t('modals.watchGame.watch')}
        </p>
      )}
      <PrimaryButton
        text={'modals.watchGame.goToSetBet'}
        onClick={goToSetBet}
      />
    </div>
  )
};
